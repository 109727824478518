import { Button } from "shared/ui/Button"
import { ButtonType, ColorEnum } from "shared/ui/Button/ui/Button"
import shareImg from 'shared/assets/icons/share-32.svg'
import cls from './ButtonProfile.module.scss'
import { classNames } from "shared/lib/classNames/classNames"
import bookmark from "shared/assets/icons/bookmark.svg"
import { Text, TextSize, TextWeight } from "shared/ui/Text/Text"
import plus from "shared/assets/icons/plus.svg"
import check from 'shared/assets/icons/check-24.svg'
import star from 'shared/assets/icons/star-24.svg'
import box from 'shared/assets/icons/box-24.svg'

export const ButtonProfile = (props) => {
    const {
        counterAccept,
        onSwitchAccepted,
        counterWant,
        onSwitchWanted,
        share,
        className,
        readonly,
        addFriend,
        myFriends,
        userId,
        onToggleWant,
        onToggleCol,
        secondSwitcher
    } = props


    let added
    if (readonly) {
        if (myFriends.includes(userId)) {
            added = true
        } else {
            added = false
        }
    }

    return (
        <div className={classNames(cls.ButtonContainer, {}, [className])}>
            {!readonly ?
                null :
                <Button
                    type={ButtonType.PROFILE}
                    onClick={addFriend}
                    className={added ? cls.ButtonProfileAdded : cls.ButtonProfileNotAdded}
                // text={'В друзья'}
                >
                    <div className={cls.Container}>
                        <img src={added ? check : plus} alt='check' className={cls.IconFriend}></img>
                        <Text className={cls.Counter} weight={TextWeight.SBOLD} size={TextSize.S}>{added ? 'В друзьях' : 'В друзья'}</Text>
                    </div>
                </Button>
            }
            <div className={cls.ButtonWrapper}>
                <Button
                    type={ButtonType.PROFILE}
                    text={'Достигнуто'}
                    color={ColorEnum.WHITE}
                    counter={counterAccept}
                    onClick={onSwitchAccepted}
                    className={cls.ButtonProfile}
                />
                <Button
                    type={ButtonType.PROFILE}
                    text={'Создано'}
                    color={ColorEnum.WHITE}
                    counter={counterWant}
                    onClick={onSwitchWanted}
                    className={cls.ButtonProfile}
                />
            </div>
            <div className={cls.ChooserWrapper}>
                <button
                    onClick={onToggleWant}
                    className={classNames(cls.Chooser, {}, [secondSwitcher === "want" ? cls.ChooserLeftActive : null])}
                >
                    <div className={cls.ButtonContentWrapper}>
                        <img className={cls.Icon} src={star} alt="icon"></img>
                        <Text weight={TextWeight.SBOLD} size={TextSize.S}>Мечты</Text>
                    </div>
                </button>
                <button
                    onClick={onToggleCol}
                    className={classNames(cls.Chooser, {}, [secondSwitcher === "collection" ? cls.ChooserRightActive : null])}
                >
                    <div className={cls.ButtonContentWrapper}>
                        <img className={cls.Icon} src={box} alt="icon"></img>
                        <Text weight={TextWeight.SBOLD} size={TextSize.S}>Коллекции</Text>
                    </div>
                </button>
            </div>
        </div >
    )
}