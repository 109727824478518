import { classNames } from "shared/lib/classNames/classNames"
import cls from './FriendsPage.module.scss'
import { Button } from "shared/ui/Button"
import { Text, TextAlign, TextColor, TextSize, TextWeight } from "shared/ui/Text/Text"
import { useDispatch, useSelector } from "react-redux"
import emptyFriends from "shared/assets/images/whale6.png"
import share from "shared/assets/icons/share-32.svg"
import { FriendsItem } from "widgets/FriendsItem/ui/FriendsItem"
import { useCallback, useEffect, useRef, useState } from "react"
import { navActions } from "entities/NavMenu"
import { useTransition } from "@react-spring/web"
import magnifier from "shared/assets/icons/magnifier.svg"
import { collection, getDocs, query, where } from "firebase/firestore"
import { db } from "app/providers/FirebaseProvider/ui/FirebaseProvider"
import xcircle from "shared/assets/icons/x-circle.svg"
import cross from "shared/assets/icons/cross-24.svg"
import { shareFunc } from "shared/lib/shareFunc/shareFunc"
import { Loader } from "shared/ui/Loader"
import { Popup } from "shared/ui/Popup/Popup"
import { copyTextToClipboard } from "shared/lib/copyTextToTextClipboard/copyTextToTextClipboard"
import { Nick } from "./Nick/Nick"

const FriendsPage = () => {
    const dispatch = useDispatch()
    const friends = useSelector(state => state.auth.friends)
    const id = useSelector(state => state.auth.id)
    const nickname = useSelector(state => state.auth.nickname)

    const collections = useSelector(state => state.collection.collections)
    let parsedCollections = JSON.parse(JSON.stringify(collections))

    const [loading, setLoading] = useState(true)
    const [loadingFetchResult, setLoadingFetchResult] = useState(false)

    const [searchValidate, setSearchValidate] = useState('')
    const [nicknameForm, setNicknameForm] = useState('')
    const [nicknameErrorText, setNicknameErrorText] = useState(``)
    const [nicknameChecking, setNicknameChecking] = useState(false)
    const [searchResult, setSearchResult] = useState([])
    const [friendsFetchResult, setFriendsFetchResult] = useState([])

    const [showCircle, setShowCircle] = useState(false)

    const [visible, setVisible] = useState(false) // стейт кита копировальщика

    const timeoutWhaleCopyRef = useRef(null) // реф для таймаута говорящего кита
    const onSpeak = useCallback(() => { // ф-ция говорящего кита
        clearTimeout(timeoutWhaleCopyRef.current);
        timeoutWhaleCopyRef.current = null;
        setVisible(true)
        timeoutWhaleCopyRef.current = setTimeout(() => {
            setVisible(false)
        }, 1000)
    }, [])

    const copyNick = (text) => {
        copyTextToClipboard(text)
        onSpeak()
    }

    const getDocumentsByIds = async (collectionName, idsArray) => { // получение списка друзей
        const docsArray = [];

        for (const id of idsArray) {
            const q = query(collection(db, collectionName), where("id", "==", id));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {

                // parsedCollections.map((item) => {
                //     item.accepted = []
                //     item.accepted = doc.data().acceptedMoments.filter((element) => {
                //         if (item.idArray.includes(element.id)) {
                //             return true
                //         }
                //     })
                // })

                // parsedCollections.map((item) => {
                //     item.received = false
                //     if (item.accepted.length >= item.goal) {
                //         item.received = true
                //     }
                // })
                docsArray.push({
                    ...doc.data(),
                    //  received: parsedCollections.filter((item) => item.received === true).length 
                });
                // docsArray.sort((a, b) => b.received - a.received)
            });
        }

        setFriendsFetchResult(docsArray)
        setLoading(false)
    };


    useEffect(() => {
        if (friends.length > 0) {
            getDocumentsByIds("Auth", friends)
        }
    }, [])

    useEffect(() => {
        dispatch(navActions.setVisible(true))
    }, [])

    const transitions = useTransition(searchResult.length > 0 ? searchResult : friendsFetchResult, {  //анимация
        from: { opacity: 0, transform: 'translate3d(0,40px,0)' },
        enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
        config: { tension: 320, friction: 20 },
        trail: 100,
    })

    const nicknameMods = {
        [cls.error]: searchValidate === 'error',
        [cls.onFocusSearch]: nicknameChecking
    }

    const onChangeNickname = (e) => {
        setNicknameForm(e.target.value.toLowerCase())
    }

    const onMatchNickname = async () => {
        setLoadingFetchResult(true)
        setNicknameErrorText(``)
        setSearchValidate('')
        setSearchResult([])
        if (nicknameChecking && nicknameForm.length > 0) {
            const getArrayOfField = async (collectionName, fieldName, condition, input) => { // получение результата поиска друга
                setShowCircle(false)
                setLoadingFetchResult(true)

                const q = query(collection(db, collectionName), where(fieldName, condition, input));
                const querySnapshot = await getDocs(q);
                const result = [];
                querySnapshot.forEach((doc) => {
                    if (doc.data().avatar) {
                        if (fieldName == "id") {
                            // parsedCollections.map((item) => {
                            //     item.accepted = []
                            //     item.accepted = doc.data().acceptedMoments.filter((element) => {
                            //         if (item.idArray.includes(element.id)) {
                            //             return true
                            //         }
                            //     })
                            // })

                            // parsedCollections.map((item) => {
                            //     item.received = false
                            //     if (item.accepted.length >= item.goal) {
                            //         item.received = true
                            //     }
                            // })

                            result.push({ ...doc.data(), received: parsedCollections.filter((item) => item.received === true).length });
                        } else {
                            if (doc.data().nickname.includes(input)) {
                                // parsedCollections.map((item) => {
                                //     item.accepted = []
                                //     item.accepted = doc.data().acceptedMoments.filter((element) => {
                                //         if (item.idArray.includes(element.id)) {
                                //             return true
                                //         }
                                //     })
                                // })

                                // parsedCollections.map((item) => {
                                //     item.received = false
                                //     if (item.accepted.length >= item.goal) {
                                //         item.received = true
                                //     }
                                // })

                                result.push({ ...doc.data(), received: parsedCollections.filter((item) => item.received === true).length });
                            }
                        }
                    }
                });
                setSearchResult(result)
                // console.log(result)
                return result;
            };

            const pattern = /^[a-z0-9_@]+$/

            if (pattern.test(nicknameForm)) {
                let input = nicknameForm.replace(/@/, "")

                if (nicknameForm.length > 30) {
                    setSearchValidate('error')
                    setNicknameErrorText('Максимальная длина - 30 символов')
                    setLoadingFetchResult(false)
                    setShowCircle(true)
                } else {
                    console.log("Строка соответствует регулярному выражению");
                    setSearchValidate('validate')
                    if (/^\d+$/.test(input)) {
                        getArrayOfField("Auth", "id", "==", +input)
                            .then((result) => {
                                setLoadingFetchResult(false)
                                setShowCircle(true)
                                if (result.length == 0) {
                                    setNicknameErrorText('Никого не нашлось... Проверь, правильно ли введён ник')
                                    setSearchValidate('error')
                                    setShowCircle(true)
                                }
                            })
                    } else {
                        console.log('stringovoe')
                        getArrayOfField("Auth", "nickname", ">=", input)
                            .then((result) => {
                                setLoadingFetchResult(false)
                                setShowCircle(true)
                                if (result.length == 0) {
                                    setNicknameErrorText('Никого не нашлось... Проверь, правильно ли введён ник')
                                    setSearchValidate('error')
                                    setShowCircle(true)
                                }
                            })
                    }
                }
            } else {
                console.log("Строка НЕ соответствует регулярному выражению");
                setSearchValidate('error')
                setNicknameErrorText('Поиск понимает только A–Z, 0–9 и _')
                setLoadingFetchResult(false)
                setShowCircle(true)
            }
        }
    }

    const errorMods = {
        [cls.errorIconLeft]: nicknameChecking,
        [cls.errorIconLeftValidate]: nicknameChecking && nicknameErrorText.length === 0
    }

    const onCloseSearch = () => {
        setNicknameChecking(false)
        setNicknameErrorText(``)
        setNicknameForm('')
        setSearchValidate('')
        setSearchResult([])
        setShowCircle(false)
    }

    const onClearForm = () => {
        setNicknameForm('')
        setNicknameErrorText(``)
        setSearchValidate('')
        setShowCircle(false)
    }

    return (
        <div className={cls.FriendsPage}
            onScroll={() => {
                document.activeElement.blur();
            }}
        >
            <Popup visible={visible} text={'Ник скопирован'} />
            <div className={cls.Container}>
                <Text weight={TextWeight.BOLD} size={TextSize.L} className={cls.Header}>
                    Мои друзья
                </Text>
            </div>
            <div className={cls.Search}>
                <div className={cls.SearchContainer}>
                    <img className={cls.magnifier} src={magnifier} alt="magnifier"></img>
                    <input
                        className={classNames(cls.inputSearch, nicknameMods, [])}
                        style={{ paddingLeft: '50px' }}
                        type="text"
                        onChange={onChangeNickname}
                        onFocus={() => {
                            setNicknameErrorText(``)
                            setSearchValidate('')
                            setNicknameChecking(true)
                        }
                        }
                        onBlur={() => {
                            if (nicknameForm.length == 0) {
                                onCloseSearch()
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onMatchNickname()
                                document.activeElement.blur();
                            }
                        }}
                        enterKeyHint="search"
                        value={nicknameForm}
                        placeholder={'Поиск по нику'}
                    />
                    {loadingFetchResult && nicknameForm.length && nicknameErrorText.length === 0 ? <Loader style={{ right: '96px', top: '18px' }} size={'small'} /> : null}
                    {nicknameChecking ?
                        <Button
                            className={cls.CrossButton}
                            onClick={onCloseSearch}
                        >
                            <img style={{ width: '24px' }} src={cross} alt="cross"></img>
                        </Button>
                        : null}
                </div>
                {showCircle ? <img onClick={onClearForm} className={classNames(cls.errorIcon, errorMods, [])} src={xcircle} alt="cross"></img> : null}
                {nicknameErrorText.length > 0 ? null : <Nick nickname={nickname} copyNick={copyNick} id={id} searchValidate={searchValidate} />}
                {nicknameErrorText.length > 0 ?
                    <Text
                        className={cls.nicknameErrorText}
                        color={searchValidate === 'error' ? TextColor.RED : TextColor.TERBLACK}
                        size={TextSize.XS}
                        align={TextAlign.LEFT}
                    >
                        {nicknameErrorText}
                    </Text>
                    : null}
            </div>
            {!nicknameChecking ? <>
                {friends.length > 0 ?
                    <div className={cls.FriendsContainer}>
                        <Button className={cls.ButtonInviteFriends} onClick={() => shareFunc(`/${nickname ? nickname : id}`)}>
                            <img style={{ 'width': '24px' }} src={share} alt="share" />
                            <Text
                                className={cls.ButtonText}
                                weight={TextWeight.SBOLD}
                            >
                                Пригласить друзей
                            </Text>
                        </Button>
                        {!loading ? <>
                            {transitions((style, item) => {
                                return (
                                    <FriendsItem
                                        style={style}
                                        id={item.id}
                                        avatar={item.avatar}
                                        name={item.name}
                                        nickname={item.nickname}
                                    // received={item.received}
                                    />
                                )
                            })}
                        </> : <div style={{ position: 'absolute', top: '50vh' }}><Loader /></div>}
                    </div> :
                    <div className={cls.EmptyFriends}>
                        <img src={emptyFriends} alt="empty" className={cls.EmptyImage}></img>
                        <Text className={cls.EmptyText} size={TextSize.XL} weight={TextWeight.BOLD}>
                            С друзьями веселей!
                        </Text>
                        <Text className={cls.EmptySecondText}>
                            Узнай ник друга и добавь его в TagLife
                        </Text>
                        <Button className={cls.Button} onClick={() => shareFunc(`/${nickname ? nickname : id}`)}>
                            <img style={{ 'width': '24px', marginLeft: '19px' }} src={share} alt="share" />
                            <Text
                                className={cls.ButtonText}
                                weight={TextWeight.SBOLD}
                            >
                                Пригласить друзей
                            </Text>
                        </Button>
                    </div>
                }
            </> :
                <div className={cls.FriendsContainer}>
                    {searchResult.length > 0 ? <>
                        {transitions((style, item) => {
                            return (
                                <FriendsItem
                                    style={style}
                                    id={item.id}
                                    avatar={item.avatar}
                                    name={item.name}
                                    nickname={item.nickname}
                                // received={item.received}
                                />
                            )
                        })}
                    </> : null}
                </div>}
        </div>
    )
}

export default FriendsPage