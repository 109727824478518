import { Button } from 'shared/ui/Button'
import cls from './HeaderCollection.module.scss'
import { Text, TextColor, TextSize, TextWeight } from 'shared/ui/Text/Text'
import { classNames } from 'shared/lib/classNames/classNames'
import chevron from 'shared/assets/icons/chevron-left-24.svg'

const HeaderCollection = (props) => {
    const { onSwitchBack, header, counterWant, counterAccept, className, fixed } = props

    let count
    header === 'Достигнуто' ? count = counterAccept : count = counterWant

    const mods = {
        [cls.Fixed]: fixed
    }

    return (
        <div className={classNames(cls.Container, mods, [className])}>
            <Button onClick={onSwitchBack} className={cls.CloseTextButton}>
                <img style={{ 'pointerEvents': 'none', 'width': '24px' }} src={chevron} alt='cross'></img>
            </Button>
            <div className={cls.Header}>
                {header}
            </div>
            <Text
                className={cls.Counter}
                weight={TextWeight.BOLD}
                size={TextSize.L}
                color={TextColor.TERBLACK}
            >
                {count}
            </Text>
        </div>

    )
}

export default HeaderCollection