import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { StoreProvider } from './app/providers/StoreProvider';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from './app/providers/ErrorBoundary';
import { FireBaseProvider } from 'app/providers/FirebaseProvider/ui/FirebaseProvider';
import { AuthProvider } from 'app/providers/AuthProvider/ui/AuthProvider';
import { LoadDataProvider } from 'app/providers/LoadDataProvider/ui/LoadDataProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ErrorBoundary>
      <FireBaseProvider>
        <StoreProvider>
          <AuthProvider>
            <LoadDataProvider />
          </AuthProvider>
        </StoreProvider>
      </FireBaseProvider>
    </ErrorBoundary>
  </BrowserRouter>
);
