import { classNames } from 'shared/lib/classNames/classNames'
import cls from './DragDrop.module.scss'
import { Text, TextAlign, TextSize, TextWeight } from 'shared/ui/Text/Text'
import edit from 'shared/assets/icons/edit.svg'
import bookmark from 'shared/assets/icons/bookmark.svg'
import copy from 'shared/assets/icons/copy.svg'
import { Link } from 'react-router-dom'
import share from 'shared/assets/icons/share-32.svg'
import { shareFunc } from 'shared/lib/shareFunc/shareFunc'
import { copyTextToClipboard } from 'shared/lib/copyTextToTextClipboard/copyTextToTextClipboard'
import messageCircle from 'shared/assets/icons/message-circle.svg'

export const DragDrop = (props) => {
    const { className, closeDragdrop, id, nickname, onSpeak } = props

    const copyText = (text) => {
        copyTextToClipboard(text)
        closeDragdrop()
        onSpeak()
    };

    const shareProfile = () => {
        shareFunc()
        closeDragdrop()
    }

    const toHelp = () => {
        window.open('https://t.me/TagLife_Help')
    }

    return (
        <div className={classNames(cls.DragDrop, {}, [className])}>
            <div className={cls.background} onMouseDown={closeDragdrop} onTouchStart={closeDragdrop}></div>
            <div className={cls.Wrapper}>
                <Link to="/settings" className={cls.item}>
                    <Text
                        className={cls.text}
                        size={TextSize.S}
                        align={TextAlign.LEFT}
                        weight={TextWeight.SBOLD}
                    >
                        Редактировать профиль
                    </Text>
                    <img className={cls.icon} src={edit} alt="edit"></img>
                </Link>
                <div className={cls.lineBold}></div>
                <div onClick={toHelp} className={cls.item}>
                    <Text
                        className={cls.text}
                        size={TextSize.S}
                        align={TextAlign.LEFT}
                        weight={TextWeight.SBOLD}
                    >
                        Написать в поддержку
                    </Text>
                    <img className={cls.icon} src={messageCircle} alt="edit"></img>
                </div>
                {/* <div onClick={() => copyText(nickname ? `@${nickname}` : `@${id}`)} className={cls.item}>
                    <Text
                        className={cls.text}
                        size={TextSize.S}
                        align={TextAlign.LEFT}
                        weight={TextWeight.SBOLD}
                    >
                        Скопировать ник
                    </Text>
                    <img className={cls.icon} src={copy} alt="edit"></img>
                </div>
                <div className={cls.line}></div> */}
                {/* <Link to="/friends" className={cls.item}>
                    <Text
                        className={cls.text}
                        size={TextSize.S}
                        align={TextAlign.LEFT}
                        weight={TextWeight.SBOLD}
                    >
                        Сохраненные профили
                    </Text>
                    <img className={cls.icon} src={bookmark} alt="edit"></img>
                </Link> */}
                {/* <div onClick={shareProfile} className={cls.item}>
                    <Text
                        className={cls.text}
                        size={TextSize.S}
                        align={TextAlign.LEFT}
                        weight={TextWeight.SBOLD}
                    >
                        Поделиться профилем
                    </Text>
                    <img className={cls.icon} src={share} alt="edit"></img>
                </div> */}
            </div>
        </div>
    )
}
