import { createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs } from "firebase/firestore";
import { db } from "app/providers/FirebaseProvider/ui/FirebaseProvider";

export const fetchCardData = createAsyncThunk(
    'card/fetchCardData',
    async (data) => {
        const cardData = await getDocs(collection(db, data))
            .then((querySnapshot) => {
                const newData = querySnapshot.docs
                    .map((doc) => ({ ...doc.data() }))
                let data = newData.sort((a, b) => a.id - b.id)
                return data
            })
        return cardData
    }

)