import { classNames } from 'shared/lib/classNames/classNames'
import cls from './Button.module.scss'
import check from 'shared/assets/icons/check-24.svg'
import plus from 'shared/assets/icons/plus.svg'
import { Text, TextAlign, TextColor, TextSize, TextWeight } from 'shared/ui/Text/Text'

export const ColorEnum = { // Список цветов
    YELLOW: 'Yellow',
    PURPLE: 'Purple',
    BLUE: 'Blue',
    GREEN: 'Green',
    ORANGE: 'Orange',
    PINK: 'Pink',
    WHITE: 'White',
}

export const ButtonType = {
    DEFAULT: 'Default',
    PROFILE: 'Profile',
}

export const Button = (props) => {
    const {
        className,
        text,
        onClick,
        color = ColorEnum.GREEN,
        disabled = false,
        type = ButtonType.DEFAULT,
        counter,
        children
    } = props

    const mods = {
        [cls[color]]: true,
        [cls[type]]: true,
    }

    if (type === 'Profile') {
        return (
            <button
                className={classNames(cls.Button, mods, [className])}
                onClick={onClick}
                disabled={disabled}
            >
                {text ?
                    <>
                        <div className={cls.Container}>
                            <div className={text === 'Создано' ? cls.IconWrapperBlue : cls.IconWrapperGreen}>
                                <img src={text === 'Создано' ? plus : check} alt='check' className={cls.Icon}></img>
                            </div>
                            <Text className={cls.Counter} weight={TextWeight.BOLD} size={TextSize.L}>{counter}</Text>
                        </div>
                        <Text
                            align={TextAlign.LEFT}
                            size={TextSize.S}
                            weight={TextWeight.SBOLD}
                            color={TextColor.SECBLACK}
                            className={cls.ButtonTitle}
                        >
                            {text}
                        </Text>
                    </> : <div className={cls.Children}>{children}</div>}
            </button>
        )
    } else {
        return (
            <button
                className={classNames(cls.Button, mods, [className])}
                onClick={onClick}
                disabled={disabled}
            >
                {text ? <div className={cls.Text}>
                    {text}
                </div> : <div className={cls.Children}>{children}</div>}
            </button>
        )
    }
}