import { combineReducers, configureStore } from "@reduxjs/toolkit";
import collectionSlice from "entities/Collection/model/slice/collectionSlice";
import navSlice from "entities/NavMenu";
import authDataSlice from "features/anonymousAuth";
import adminSlice from "features/anonymousAuth/model/slice/adminSlice";
import modeLogicSlice from "features/modeLogic/model/slice/modeLogicSlice";
import otherProfileDataSlice from "features/otherProfileData/model/slice/otherProfileDataSlice";
import specialSlice from "features/specialData/model/slice/specialSlice";

const rootReducer = combineReducers({
    nav: navSlice,
    auth: authDataSlice,
    special: specialSlice,
    mode: modeLogicSlice,
    collection: collectionSlice,
    otherProfileData: otherProfileDataSlice,
    admin: adminSlice,
})

export const store = configureStore({
    reducer: rootReducer
})