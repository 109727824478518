import { FloatModal } from "shared/ui/FloatModal"
import { Text, TextAlign, TextSize, TextWeight } from "shared/ui/Text/Text"
import cls from "./ProfileFloat.module.scss"
import { Button } from "shared/ui/Button"
import logo from 'shared/assets/images/profile.png'
import push from 'shared/assets/icons/IconProfile.png'

export const ProfileFLoat = (props) => {
    const { closeProfileFloat, onRedirectToProfile } = props

    return (
        <FloatModal onClose={closeProfileFloat}>
            <div className={cls.ImageContainer}>
                <img className={cls.Image} alt='TagLife' src={logo}></img>
                <img className={cls.Icon} alt='TagLife' src={push}></img>
            </div>
            <Text
                className={cls.TitleTextFloat}
                size={TextSize.XL}
                align={TextAlign.LEFT}
                weight={TextWeight.BOLD}
            >
                Мечты ждут тебя!
            </Text>
            <Text
                className={cls.TextFloat}
                align={TextAlign.LEFT}
                weight={TextWeight.MED}
            >
                Твои «Мечты» и «Достигнутое» уже в твоём профиле
            </Text>
            <Button
                onClick={onRedirectToProfile}
                className={cls.FloatButton}
                text={'Осмотреть профиль'}>
            </Button>
        </FloatModal>
    )
}