import AppIcon from 'shared/assets/images/logoWhale.png'
import cls from './TagLifeLoader.module.scss'
import { Loader } from 'shared/ui/Loader'
// import Snowfall from 'react-snowfall'

export const TagLifeLoader = () => {
    return (
        <div className={cls.Container}>
            {/* <Snowfall
                color="#fff"
            /> */}
            <img className={cls.Icon} src={AppIcon} alt="TagLife"></img>
            <div className={cls.Text}>Твои мечты</div>
            <Loader className={cls.loader} />
        </div>
    )
}

