export const shareFunc = (url) => {
    if (navigator.share) {
        if (typeof url == 'string') {
            navigator.share({
                title: 'TagLife',
                text: 'Зацени',
                url: url,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        } else {
            navigator.share({
                title: 'TagLife',
                text: 'Зацени',
                url: `${window.location.href}`,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        }
    }
}