import { classNames } from "shared/lib/classNames/classNames"
import cls from '../InstallWidget.module.scss'
import { Button } from "shared/ui/Button"
import logo from 'shared/assets/icons/AppIcon2.png'
import install from 'shared/assets/icons/IconInstall.png'
import { Cross } from "shared/ui/Cross"

export const AndroidInstall = (props) => {
    const { onDownload, onCloseInstall, cross, text } = props

    return (
        <div className={classNames(cls.InstallWidget, {}, [])}>
            {cross ? <Cross onClose={onCloseInstall} /> : null}
            <div className={cls.ImageContainer}>
                <img className={cls.Image} alt='TagLife' src={logo}></img>
                <img className={cls.Icon} alt='TagLife' src={install}></img>
            </div>
            <div className={cls.Text}>{text}</div>
            <Button onClick={onDownload} className={cls.Button} text={'Скачать APK'}></Button>
            <div className={cls.SecondText}>При установке нажми <b>“Подробнее”</b> а потом <b>”Всё равно установить”</b></div>
        </div>
    )
}