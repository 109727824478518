import { db } from "app/providers/FirebaseProvider/ui/FirebaseProvider";
import { authActions } from "features/anonymousAuth";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth"
import { collection, doc, getDoc, limit, orderBy, query, setDoc, getDocs, updateDoc, runTransaction } from "firebase/firestore";
import { useDispatch } from "react-redux";

export const AuthProvider = (props) => {
    const dispatch = useDispatch()
    const auth = getAuth();
    signInAnonymously(auth)
    onAuthStateChanged(auth, async (user) => { //получение uid
        if (user) {
            let uid = user.uid;
            dispatch(authActions.setUid(user.uid))

            const docRef = doc(db, 'Auth', uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) { //если пользователь существует
                if (!docSnap.data().pinnedMoments && !docSnap.data().avatar) { // но у него нет новых полей
                    const lastAuth = new Date()
                    const userData = {
                        id: docSnap.data().id,
                        nickname: null,
                        name: 'Мечтатель',
                        avatar: 'https://firebasestorage.googleapis.com/v0/b/taglife-images/o/avatars%2F0.png?alt=media&token=eef363ee-afa6-410b-8ea6-eb625ae4b603',
                        friends: [],
                        descr: null,
                        background: 'https://firebasestorage.googleapis.com/v0/b/taglife-images/o/backgrounds%2F0.png?alt=media&token=cbe59a35-79bb-46d2-b9d2-311bc4dcd89c',
                        "authTime.lastAuth": lastAuth,
                        acceptedMoments: docSnap.data().acceptedMoments,
                        wantedMoments: docSnap.data().wantedMoments,
                        rejectedMoments: docSnap.data().rejectedMoments,
                        pinnedMoments: []
                    }

                    updateDoc(docRef, userData, { merge: true })
                    // let doc = docSnap.data()
                    // userData.authTime.firstAuth = +new Date(doc.authTime.firstAuth.seconds)
                    // userData.authTime.lastAuth = +new Date(doc.authTime.lastAuth.seconds)

                    dispatch(authActions.setUserData(userData))
                } else { // есть новые поля
                    const lastAuth = new Date()
                    updateDoc(docRef, {
                        "authTime.lastAuth": lastAuth
                    }, { merge: true })
                    let doc = docSnap.data()
                    doc.authTime.firstAuth = +new Date(docSnap.data().authTime.firstAuth.seconds)
                    doc.authTime.lastAuth = +new Date(docSnap.data().authTime.lastAuth.seconds)

                    dispatch(authActions.setUserData(doc))
                    console.log(doc)
                }


            } else { //если не существует
                // const ref = collection(db, 'Auth')
                // const q = query(ref, orderBy('id', 'desc'), limit(1))
                // let lastId
                // getDocs(q)
                //     .then((snapshot) => {
                //         lastId = snapshot.docs[0].data().id + 1
                //         const firstAuth = new Date()
                //         const userData = {
                //             id: lastId,
                //             nickname: null,
                //             name: 'Охотник за достижениями',
                //             avatar: 'https://firebasestorage.googleapis.com/v0/b/taglife-images/o/avatars%2F0.png?alt=media&token=1568d865-e09c-4a87-9b99-2908fef0d0ac',
                //             friends: [],
                //             descr: null,
                //             background: 'https://firebasestorage.googleapis.com/v0/b/taglife-images/o/backgrounds%2F0.png?alt=media&token=fdcd1b6e-7128-417e-a4dc-da277f6f9fca',
                //             authTime: { firstAuth: firstAuth, lastAuth: firstAuth },
                //             acceptedMoments: [],
                //             wantedMoments: [],
                //             rejectedMoments: []
                //         }
                //         setDoc(doc(db, "Auth", uid), userData);
                //         dispatch(authActions.setUserData(userData))
                //     })

                try {
                    await runTransaction(db, async (transaction) => {
                        const ref = doc(db, "CounterId", "counter")
                        // const q = query(ref, orderBy('id', 'desc'), limit(1))
                        const sfDoc = await transaction.get(ref)
                        const counter = sfDoc.data().counter
                        console.log(counter)
                        transaction.update(ref, { counter: counter + 1 })
                        return counter
                    })
                        .then((counter) => {
                            const firstAuth = new Date()
                            const userData = {
                                id: counter,
                                nickname: null,
                                name: 'Мечтатель',
                                avatar: 'https://firebasestorage.googleapis.com/v0/b/taglife-images/o/avatars%2F0.png?alt=media&token=eef363ee-afa6-410b-8ea6-eb625ae4b603',
                                friends: [],
                                descr: null,
                                background: 'https://firebasestorage.googleapis.com/v0/b/taglife-images/o/backgrounds%2F0.png?alt=media&token=cbe59a35-79bb-46d2-b9d2-311bc4dcd89c',
                                authTime: { firstAuth: firstAuth, lastAuth: firstAuth },
                                acceptedMoments: [],
                                wantedMoments: [],
                                rejectedMoments: [],
                                pinnedMoments: [],
                            }
                            setDoc(doc(db, "Auth", uid), userData);
                            dispatch(authActions.setUserData(userData))
                        })
                } catch (e) {
                    console.log("Transaction failed: ", e);
                }
            }
        } else {
            // User is signed out
            // ...
        }
    });


    const { children } = props

    return (
        <>
            {children}
        </>
    )
}