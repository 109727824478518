import { createSlice } from "@reduxjs/toolkit";
import { db } from "app/providers/FirebaseProvider/ui/FirebaseProvider";
import { fetchCardData } from "entities/Card";
import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore";
import { randomColor } from "shared/lib/randomColor/randomColor";

const initialState = {
  uid: undefined,
  acceptedMoments: [],
  wantedMoments: [],
  rejectedMoments: [],
  createdMoments: [],
  pinnedMoments: [],
  allCards: [],
  cards: [], // карточка
  status: null,
  error: null,
  direction: [0, 0], //направление анимации при нажатии на кнопку
  arrayCard: [], //массив из одной карточки на MomentPage
  isLoaded: true,
  id: undefined,
  name: "",
  avatar: "",
  descr: "",
  nickname: "",
  background: "",
  friends: [],
  isAdmin: false,
};

const reducer = (state, action) => {
  state.arrayCard.pop();
  if (state.cards.length !== 0) {
    state.arrayCard.unshift(...state.cards.splice(0, 1));
    if (!state.arrayCard[0].color) {
      state.arrayCard[0].color = randomColor();
    }
  }

  // if (state.acceptedMoments.length === 0 &&
  //     state.rejectedMoments.length === 0 &&
  //     state.wantedMoments.length === 0) { // добавление рекламной карточки коллекции
  //     const collectionOpenCard = {
  //         id: 0,
  //         img: 'https://files.taglife.site/img/0_collections.png',
  //         text: 'Увидеть коллекции в профиле',
  //         color: 'FOURTH'
  //     }

  //     state.arrayCard.splice(0, 0, collectionOpenCard)
  //     // console.log(cardData)

  // }

  state.direction = action.payload[1];
  // state.isLoaded = false
};

const authDataSlice = createSlice({
  name: "authData",
  initialState,
  reducers: {
    setUid(state, action) {
      state.uid = action.payload;
    },
    setUserData(state, action) {
      state.acceptedMoments = action.payload.acceptedMoments;
      state.wantedMoments = action.payload.wantedMoments;
      state.rejectedMoments = action.payload.rejectedMoments;
      state.pinnedMoments = action.payload.pinnedMoments;

      state.id = action.payload.id;
      state.name = action.payload.name;
      state.avatar = action.payload.avatar;
      state.descr = action.payload.descr;
      state.nickname = action.payload.nickname;
      state.background = action.payload.background;
      state.friends = action.payload.friends;
      state.isAdmin = action.payload.admin;
    },
    acceptMoment(state, action) {
      if (action.payload[0].id === 0) {
        reducer(state, action);
      } else {
        reducer(state, action);
        const time = +new Date();
        state.acceptedMoments.push({ id: action.payload[0].id, time: time });
        const ref = doc(db, "Auth", state.uid);
        updateDoc(ref, {
          acceptedMoments: arrayUnion({ id: action.payload[0].id, time: time }),
        });
      }
    },
    wantMoment(state, action) {
      if (action.payload[0].id === 0) {
        reducer(state, action);
      } else {
        reducer(state, action);
        const time = +new Date();
        state.wantedMoments.push({ id: action.payload[0].id, time: time });
        const ref = doc(db, "Auth", state.uid);
        updateDoc(ref, {
          wantedMoments: arrayUnion({ id: action.payload[0].id, time: time }),
        });
      }
    },
    rejectMoment(state, action) {
      if (action.payload[0].id === 0) {
        reducer(state, action);
      } else {
        reducer(state, action);
        const time = +new Date();
        state.rejectedMoments.push({ id: action.payload[0].id, time: time });
        const ref = doc(db, "Auth", state.uid);
        updateDoc(ref, {
          rejectedMoments: arrayUnion({ id: action.payload[0].id, time: time }),
        });
      }
    },
    updateMoment(state, action) {
      // переключение хочу/сделано
      let deleteArray = [];
      let pushArray = [];
      const ref = doc(db, "Auth", state.uid);
      if (action.payload[0] === "accepted") {
        pushArray = state.wantedMoments;
        deleteArray = state.acceptedMoments;
      } else if (action.payload[0] === "wanted") {
        pushArray = state.acceptedMoments;
        deleteArray = state.wantedMoments;
      }
      const index = deleteArray.find((item) => {
        return item.id === action.payload[1];
      });

      deleteArray.splice(
        deleteArray.findIndex((element) => element.id === index.id),
        1
      );
      const time = +new Date();
      pushArray.push({ id: index.id, time: time });

      if (action.payload[0] === "accepted") {
        updateDoc(ref, {
          wantedMoments: arrayUnion({ id: index.id, time: time }),
          acceptedMoments: arrayRemove(index),
        });
      } else if (action.payload[0] === "wanted") {
        updateDoc(ref, {
          acceptedMoments: arrayUnion({ id: index.id, time: time }),
          wantedMoments: arrayRemove(index),
        });
      }
    },
    updateMomentCollection(state, action) {
      let deleteArray = [];
      let pushArray = [];
      const ref = doc(db, "Auth", state.uid);
      if (action.payload[0] === "accepted") {
        pushArray = state.rejectedMoments;
        deleteArray = state.acceptedMoments;
      } else if (action.payload[0] === "wanted") {
        pushArray = state.acceptedMoments;
        deleteArray = state.wantedMoments;
      } else if (action.payload[0] === "rejected") {
        pushArray = state.wantedMoments;
        deleteArray = state.rejectedMoments;
      }
      const index = deleteArray.find((item) => {
        return item.id === action.payload[1];
      });

      deleteArray.splice(
        deleteArray.findIndex((element) => element.id === index.id),
        1
      );
      // const time = + new Date()
      pushArray.push({ id: index.id, time: index.time });

      if (action.payload[0] === "accepted") {
        updateDoc(ref, {
          rejectedMoments: arrayUnion({ id: index.id, time: index.time }),
          acceptedMoments: arrayRemove(index),
        });
      } else if (action.payload[0] === "wanted") {
        updateDoc(ref, {
          acceptedMoments: arrayUnion({ id: index.id, time: index.time }),
          wantedMoments: arrayRemove(index),
        });
      } else if (action.payload[0] === "rejected") {
        updateDoc(ref, {
          wantedMoments: arrayUnion({ id: index.id, time: index.time }),
          rejectedMoments: arrayRemove(index),
        });
      }
    },
    isLoaded(state) {
      state.isLoaded = true;
    },
    updateAvatar(state, action) {
      state.avatar = action.payload;
      const ref = doc(db, "Auth", state.uid);
      updateDoc(ref, {
        avatar: action.payload,
      });
    },
    updateBackground(state, action) {
      state.background = action.payload;
      const ref = doc(db, "Auth", state.uid);
      updateDoc(ref, {
        background: action.payload,
      });
    },
    updateSettings(state, action) {
      state.name = action.payload[0];
      state.nickname = action.payload[1];
      state.descr = action.payload[2];

      const ref = doc(db, "Auth", state.uid);

      updateDoc(ref, {
        name: action.payload[0],
        nickname: action.payload[1],
        descr: action.payload[2],
      });
    },
    addFriend(state, action) {
      state.friends.push(action.payload);
      const ref = doc(db, "Auth", state.uid);
      updateDoc(ref, {
        friends: state.friends,
      });
    },
    deleteFriend(state, action) {
      state.friends = state.friends.filter((item) => item !== action.payload);
      const ref = doc(db, "Auth", state.uid);
      updateDoc(ref, {
        friends: state.friends,
      });
    },
    toAcceptedMoment(state, action) {
      const ref = doc(db, "Auth", state.uid);
      const time = +new Date();

      const index = state.wantedMoments.find((item) => {
        return item.id === action.payload;
      });

      const index2 = state.pinnedMoments.find((item) => {
        return item.id === action.payload;
      });

      if (index2) {
        updateDoc(ref, {
          acceptedMoments: arrayUnion({ id: action.payload, time: time }),
          wantedMoments: arrayRemove(index),
          pinnedMoments: arrayRemove(index2),
        });
      } else {
        updateDoc(ref, {
          acceptedMoments: arrayUnion({ id: action.payload, time: time }),
          wantedMoments: arrayRemove(index),
        });
      }

      state.acceptedMoments.push({ id: action.payload, time: time });
      state.wantedMoments = state.wantedMoments.filter(
        (item) => item.id !== action.payload
      );
      state.pinnedMoments = state.pinnedMoments.filter(
        (item) => item.id !== action.payload
      );
    },
    toWantedMoment(state, action) {
      const ref = doc(db, "Auth", state.uid);
      const time = +new Date();

      updateDoc(ref, {
        wantedMoments: arrayUnion({ id: action.payload, time: time }),
      });

      state.wantedMoments.push({ id: action.payload, time: time });
    },
    toRejectedMoment(state, action) {
      const ref = doc(db, "Auth", state.uid);
      const time = +new Date();

      const index = state.wantedMoments.find((item) => {
        return item.id === action.payload;
      });

      const index2 = state.pinnedMoments.find((item) => {
        return item.id === action.payload;
      });

      if (index2) {
        updateDoc(ref, {
          rejectedMoments: arrayUnion({ id: action.payload, time: time }),
          wantedMoments: arrayRemove(index),
          pinnedMoments: arrayRemove(index2),
        });
      } else {
        updateDoc(ref, {
          rejectedMoments: arrayUnion({ id: action.payload, time: time }),
          wantedMoments: arrayRemove(index),
        });
      }

      state.rejectedMoments.push({ id: action.payload, time: time });
      state.wantedMoments = state.wantedMoments.filter(
        (item) => item.id !== action.payload
      );
      state.pinnedMoments = state.pinnedMoments.filter(
        (item) => item.id !== action.payload
      );
    },
    toPinnedMoment(state, action) {
      const ref = doc(db, "Auth", state.uid);
      const time = +new Date();

      updateDoc(ref, {
        pinnedMoments: arrayUnion({ id: action.payload, time: time }),
      });

      state.pinnedMoments.push({ id: action.payload, time: time });
    },
    fromPinnedMoment(state, action) {
      const ref = doc(db, "Auth", state.uid);

      const index = state.pinnedMoments.find((item) => {
        return item.id === action.payload;
      });

      updateDoc(ref, {
        pinnedMoments: arrayRemove(index),
      });

      state.pinnedMoments = state.pinnedMoments.filter(
        (item) => item.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCardData.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchCardData.fulfilled, (state, action) => {
        state.allCards = action.payload;

        for (let i = 0; i < state.allCards.length; i++) {
          if (!state.allCards[i].color) {
            state.allCards[i].color = randomColor();
          }
        }

        state.createdMoments = state.allCards.filter((item) => {
          if (item.userId === state.id && item.userId !== undefined) {
            return true;
          }
        });
        // state.createdCardData.map((item) => {
        //     if (state.acceptedMoments.includes(item.id)) {
        //         item.checked = 'accepted'
        //     } else if (state.wantedMoments.includes(item.id)) {
        //         item.checked = 'wanted'
        //     } else if (state.rejectedMoments.includes(item.id)) {
        //         item.checked = 'rejected'
        //     }
        // })

        let filtratedCardData = state.allCards
          .filter((item) => {
            // фильтрация полученного массива карточек полученными массивами с firebase

            let arrayId = state.acceptedMoments.concat(
              state.rejectedMoments,
              state.wantedMoments
            ); // объединение в один
            let ids = [];
            arrayId.map((item) => {
              ids.push(item.id);
            });
            return !ids.includes(item.id);
          })
          .sort(() => Math.random() - 0.5);

        state.cards = filtratedCardData;
        state.arrayCard = filtratedCardData.splice(0, 3).slice().reverse();

        state.status = "resolved";
      })
      .addCase(fetchCardData.rejected, (state, action) => {
        throw new Error("Error from fetchCardData");
      });
  },
});

export default authDataSlice.reducer;
export const { actions: authActions } = authDataSlice;
