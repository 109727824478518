import authSlice, { authActions } from "./model/slice/authDataSlice";
import {
    getUid,
    getAcceptedMoments,
    getWantedMoments,
    getRejectedMoments,
    getAllCards,
    getCards,
    getStatus,
    getError,
    getDirection,
    getArrayCard,
    getIsLoaded
} from "./model/selectors/getSelectors";

export {
    getUid,
    getAcceptedMoments,
    getWantedMoments,
    getRejectedMoments,
    getAllCards,
    getCards,
    getStatus,
    getError,
    getDirection,
    getArrayCard,
    getIsLoaded,
    authActions
}

export default authSlice