import { classNames } from "shared/lib/classNames/classNames"
import cls from "./GifFloat.module.scss"
import magnifier from "shared/assets/icons/magnifier.svg"
import { AsyncImage } from "loadable-image"
import { Button } from "shared/ui/Button"
import { Loader } from "shared/ui/Loader"
import cross from "shared/assets/icons/cross-24.svg"
import xcircle from "shared/assets/icons/x-circle.svg"

export const GifFloat = (props) => {
    const {
        onChangeValue,
        onChangeSearch,
        loadingFetchResult,
        onClearForm,
        onCloseFloatImage,
        imageArray,
        pickImage,
        searchText
    } = props

    // const setFocus = (input) => {
    //     input && input.focus()
    //     // input && input.focus()
    // }

    return (
        <div className={cls.FloatModal}>
            <div onClick={(e) => e.stopPropagation()} className={cls.Content} >
                <div className={cls.SearchContainer}>
                    <img className={cls.magnifier} src={magnifier} alt="magnifier"></img>
                    <input
                        className={classNames(cls.inputSearch, {}, [])}
                        style={{ paddingLeft: '50px' }}
                        type="text"
                        enterKeyHint="search"
                        value={onChangeValue}
                        ref={(input) => {
                            if (!searchText) {
                                input && input.focus()
                            }
                        }}
                        onChange={onChangeSearch}
                        placeholder={'Поиск GIF'}
                        // autoFocus
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                document.activeElement.blur();
                            }
                        }}
                    />
                    {loadingFetchResult ? <Loader style={{ right: '106px', top: '30px' }} size={'small'} /> : null}
                    {!loadingFetchResult && onChangeValue.length > 0 ? <img onClick={onClearForm} className={cls.clearIcon} src={xcircle} alt="cross"></img> : null}
                    <Button
                        className={cls.CrossButton}
                        onClick={onCloseFloatImage}
                    >
                        <img style={{ width: '24px' }} src={cross} alt="cross"></img>
                    </Button>
                    {/* : null} */}
                </div>
                <div className={cls.ImageArray}>
                    {imageArray ? imageArray.map((item, i) => {
                        return (
                            <AsyncImage
                                key={i}
                                onClick={() => pickImage(item.media_formats.tinygif.url)}
                                src={item.media_formats.tinygif.url}
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                // className={cls.imageTenor}
                                loader={<div style={{ background: '#F1F1F1' }} />}
                                error={<div style={{ background: '#eee' }} />}
                            />
                            // <img key={i} className={cls.ImageTenor} src={item.media_formats.tinygif.url} onClick={() => pickImage(item.media_formats.tinygif.url)} loading="lazy"></img>
                        )
                    }) : null}
                </div>
            </div>
        </div>
    )
}