import { getInstallPossibility, getMode } from 'features/modeLogic'
import { getSpecialArrayCard } from 'features/specialData'
import { MomentPage } from 'pages/MomentPage'
import { useSelector } from 'react-redux'
import { InstallWidget } from 'widgets/InstallWidget/ui/InstallWidget'

const SpecialPage = (props) => {
    const { installPrompt } = props
    const cardData = useSelector(getSpecialArrayCard)
    const installPossibility = useSelector(getInstallPossibility)
    const mode = useSelector(getMode)

    let installPossibilityWidget
    if (installPossibility) {
        installPossibilityWidget = <InstallWidget installPrompt={installPrompt} />
    } else {
        installPossibilityWidget = <InstallWidget />
    }

    return (
        <>
            {cardData.length ? <MomentPage mode={mode} /> : installPossibilityWidget}
        </>
    )
}

export default SpecialPage
