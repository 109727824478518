import { useDispatch, useSelector } from "react-redux"
import { CardButton } from "shared/ui/CardButton"
import cls from './Choosebar.module.scss'
import { authActions, getArrayCard, getIsLoaded } from "features/anonymousAuth"
import { specialActions } from "features/specialData/model/slice/specialSlice"
import { memo } from "react"
import { classNames } from "shared/lib/classNames/classNames"
import { getSpecialArrayCard } from "features/specialData"

export const Choosebar = memo((props) => {
    const { clearInterval, activeButton, clearOnBoard, onSpeak, mode } = props
    const dispatch = useDispatch()
    const isLoaded = useSelector(getIsLoaded)
    let cardData = useSelector(mode === 'special' ? getSpecialArrayCard : getArrayCard) // если special то другой редюсер
    cardData = cardData.slice().reverse()
    const showedCardData = [...cardData.splice(0, 1)]

    const onAccept = () => {
        // if (cardData[0].speak) {
        onSpeak(showedCardData[0].speak)
        // }

        mode === 'special' ?
            dispatch(specialActions.wantMoment([showedCardData[0], [1000, 0]])) :
            dispatch(authActions.acceptMoment([showedCardData[0], [1000, 0]]))
        clearInterval()
        clearOnBoard()
    }

    const onReject = () => {
        onSpeak(showedCardData[0].speak)

        mode === 'special' ?
            dispatch(specialActions.rejectMoment([showedCardData[0], [-1000, 0]])) :
            dispatch(authActions.rejectMoment([showedCardData[0], [-1000, 0]]))
        clearInterval()
        clearOnBoard()
    }

    const onWant = () => {
        onSpeak(showedCardData[0].speak)

        mode === 'special' ?
            dispatch(specialActions.wantMoment([showedCardData[0], [0, -2000]])) :
            dispatch(authActions.wantMoment([showedCardData[0], [0, -2000]]))
        clearInterval()
        clearOnBoard()
    }

    const modeChoose = {
        [cls.Disabled]: !isLoaded
    }

    return (
        <div className={classNames(cls.Choosebar, modeChoose, [])}>
            <CardButton
                activeButton={activeButton}
                action={onReject}
                type='reject'
            />
            <CardButton
                activeButton={activeButton}
                action={onWant}
                type='want'
            />
            <CardButton
                activeButton={activeButton}
                action={onAccept}
                type='accept'
            />
        </div>
    )
})