import { classNames } from "shared/lib/classNames/classNames"
import cls from './Collection.module.scss'
import { Text, TextAlign, TextColor, TextSize, TextWeight } from "shared/ui/Text/Text"
import { ProgressItem } from "widgets/ProgressItem/ProgressItem"
import goalImg from "shared/assets/icons/goal-32.svg"
import chevron from "shared/assets/icons/chevron-right-24.png"
import { animated } from '@react-spring/web'
import { Deck } from "widgets/Deck/Deck"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useSelector } from "react-redux"

export const Collection = (props) => {
    const {
        className,
        goal,
        // id,
        idArray,
        img,
        link,
        name,
        style,
        finded,
        accepted,
        lastFinded,
        secondFinded,
        received,
        level,
        progress,
        max,
        readonly,
        announce,
        openAnnounceFloat
    } = props

    const { id } = useParams()

    const [active, setActive] = useState(false)
    const [newLevel, setNewLevel] = useState(false)

    const userId = useSelector(state => state.auth.id)

    const mouseDown = () => {
        setActive(true)
    }

    const mouseUp = () => {
        setActive(false)
    }

    useEffect(() => {
        if (accepted) {
            let col = localStorage.getItem(`${name}`)
            if (+col !== accepted.length) {
                // console.log(`noviy lvl y ${name}`)
                setNewLevel(true)
            }
            if (!readonly && !col) {
                localStorage.setItem(`${name}`, accepted.length)
            }
        }
    }, [])

    // let mods = {
    //     [cls.CollectionReceived]: received
    // }
    let gradientMods = {
        [cls.gradientAnnounce]: announce,
    }

    if (announce) {
        return (
            <animated.div
                onTouchStart={mouseDown}
                onTouchEnd={mouseUp}
                onClick={openAnnounceFloat}
                style={active ?
                    {
                        ...style,
                        transition: 'all 0.05s linear',
                        transform: 'translate3d(3px,3px,0)',
                        boxShadow: '0px 0px'
                    }
                    : style}
                className={classNames(cls.Collection, {}, [className])}
            >
                <div className={classNames(cls.Gradient, gradientMods, [])}></div>
                <img src={img} alt={'background'} className={cls.Background}></img>
                <div className={cls.FlexWrapper}>
                    <Deck
                    // received={received}
                    // lastFinded={lastFinded}
                    // secondFinded={secondFinded}
                    />
                    <div className={cls.Progress}>
                        <Text align={TextAlign.LEFT} color={TextColor.WHITE} weight={TextWeight.BOLD}>{name}</Text>
                        <Text className={cls.announceText} size={TextSize.S} align={TextAlign.LEFT} color={TextColor.WHITE} weight={TextWeight.SBOLD}>{announce}</Text>
                    </div>
                </div>
                <img className={cls.Chevron} src={chevron} alt="chev"></img>
            </animated.div>
        )
    }

    return (
        <animated.div
            onTouchStart={mouseDown}
            onTouchEnd={mouseUp}
            style={active ?
                {
                    ...style,
                    transition: 'all 0.05s linear',
                    transform: 'translate3d(3px,3px,0)',
                    boxShadow: '0px 0px'
                }
                : style}
            className={classNames(cls.Collection, {}, [className])}
        >
            <Link to={`/${id}/${link}`} style={{ 'textDecoration': 'none' }}>
                <div className={cls.Gradient}></div>
                <img src={img} alt={'background'} className={cls.Background}></img>
                <div className={cls.FlexWrapper}>
                    <Deck
                        received={received}
                        lastFinded={lastFinded}
                        secondFinded={secondFinded}
                    />
                    <div className={cls.Progress}>
                        <Text align={TextAlign.LEFT} color={TextColor.WHITE} weight={TextWeight.BOLD}>{name}</Text>
                        <div className={cls.ProgressBar}>
                            {level || max ? <ProgressItem
                                accepted={accepted}
                                text={'сделано'}
                                level={level}
                                max={max}
                                newLevel={newLevel}
                                readonly={readonly}
                            /> : null}
                        </div>
                        {/* <div style={{ color: 'white' }}>{level}lvl {progress}%</div> */}
                    </div>
                </div>
                <img className={cls.Chevron} src={chevron} alt="chev"></img>
                <div className={cls.progress}>
                    <div style={{ width: `${progress}%` }} className={cls.progressBar}></div>
                </div>
            </Link>
        </animated.div>
    )
}