import { Text, TextAlign, TextColor, TextSize, TextWeight } from "shared/ui/Text/Text"
import cls from './ProgressItem.module.scss'
import { classNames } from "shared/lib/classNames/classNames"
import chevronsUp from "shared/assets/icons/chevrons-up.svg"

export const ProgressItem = (props) => {
    const {
        text,
        className,
        level,
        max,
        newLevel,
        readonly,
        accepted
    } = props

    return (
        <div className={classNames(cls.ProgressItem, {}, [className])}>
            <div className={cls.ProgressItemWrapper}>
                {newLevel && !readonly ? <img className={cls.chevrons} src={chevronsUp} alt="chevrons" /> : null}
                <Text
                    className={classNames(cls.ProgressCount, {}, [])}
                    align={TextAlign.LEFT}
                    color={TextColor.WHITE}
                    weight={TextWeight.BOLD}
                    size={TextSize.XL}
                >
                    {max ? 'max' : accepted.length}
                </Text>
                <Text
                    className={cls.TextReceived}
                    align={TextAlign.LEFT}
                    color={TextColor.WHITE}
                    size={TextSize.S}
                >
                    {text}
                </Text>
            </div>
        </div>
    )
}