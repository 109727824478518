import cls from './FloatModal.module.scss'
import cross from 'shared/assets/icons/cross-24.svg'

export const FloatModal = (props) => {
    const { children, onClose } = props

    return (
        <div onClick={onClose} className={`${cls.FloatModal}`}>
            <div onClick={(e) => e.stopPropagation()} className={cls.Content} >
                {/* <div onClick={onClose} className={cls.CrossButton}>
                    <img className={cls.Cross} src={cross} alt="cross"></img>
                </div> */}
                {children}
            </div>
        </div>
    )
}