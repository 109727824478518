import { createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "app/providers/FirebaseProvider/ui/FirebaseProvider";

export const fetchOtherProfileData = createAsyncThunk(
    'profile/fetchOtherProfileData',
    async (id) => {
        let field

        if (typeof id == 'number') {
            field = "id"
        } else if (typeof id == 'string') {
            field = "nickname"
        }

        const q = query(collection(db, "Auth"), where(field, "==", id));
        const querySnapshot = await getDocs(q)

        const otherProfileData = querySnapshot.docs.map((doc) => ({ ...doc.data() }))

        otherProfileData[0].authTime.firstAuth = +new Date(otherProfileData[0].authTime.firstAuth.seconds)
        otherProfileData[0].authTime.lastAuth = +new Date(otherProfileData[0].authTime.lastAuth.seconds)
        return otherProfileData
    }

)