import { createSlice } from "@reduxjs/toolkit";
import { fetchCardData } from "entities/Card";
import { randomColor } from "shared/lib/randomColor/randomColor";

const initialState = {
    allCards: [],
    cards: [], // карточка
    status: null,
    error: null,
    direction: [0, 0], //направление анимации при нажатии на кнопку
    arrayCard: [], //массив из одной карточки на MomentPage
    isLoaded: false,
    collection: [],
}

const reducer = (state, action) => {
    state.collection.push(action.payload[0])
    state.arrayCard.pop()
    if (state.cards.length !== 0) {
        state.arrayCard.unshift(...state.cards.splice(0, 1))
        if (!state.arrayCard[0].color) {
            state.arrayCard[0].color = randomColor()
        }
    }

    state.direction = action.payload[1]
    state.isLoaded = false
}

const specialSlice = createSlice({
    name: 'specialSlice',
    initialState,
    reducers: {
        acceptMoment(state, action) { // зачем три одинаковых действия?
            reducer(state, action)
        },
        wantMoment(state, action) {
            reducer(state, action)
        },
        rejectMoment(state, action) {
            reducer(state, action)
        },
        isLoaded(state) {
            state.isLoaded = true
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                fetchCardData.pending, (state) => {
                    state.status = 'loading'
                    state.error = null
                }
            )
            .addCase(
                fetchCardData.fulfilled, (state, action) => {
                    state.allCards = action.payload

                    for (let i = 0; i < state.allCards.length; i++) {
                        if (!state.allCards[i].color) {
                            state.allCards[i].color = randomColor()
                        }
                    }

                    let filtratedCardData = state.allCards.slice()

                    state.cards = filtratedCardData
                    if (filtratedCardData.length != 0) {
                        state.arrayCard = filtratedCardData.splice(0, 3).slice().reverse()
                    }

                    state.status = 'resolved'
                }
            )
            .addCase(
                fetchCardData.rejected, (state, action) => {
                    throw new Error('Error from fetchCardData')
                }
            )
    }
})

export default specialSlice.reducer
export const { actions: specialActions } = specialSlice