import cls from './GridCollection.module.scss'
import { useEffect } from 'react'
import EmptyCollectionPage from '../EmptyCollectionPage/EmptyCollectionPage'
import GridElement from './GridElement/GridElement'
import { useDispatch } from 'react-redux'
import { navActions } from 'entities/NavMenu'
import HeaderCollection from '../HeaderCollection/HeaderCollection'

//фильтрацию коллекции и анимацию в 
//отдельную функцию

const GridCollection = (props) => {
    const { collection, switcher, header, counterAccept, counterWant, onSwitchBack, readonly } = props

    const dispatch = useDispatch()

    console.log(collection)

    useEffect(() => {
        dispatch(navActions.setVisible(false))
    }, [])

    let todayCollection = []
    let yesterdayCollection = []
    let weekCollection = []
    let monthCollection = []
    let laterCollection = []
    let finalCollection = []
    collection.map((item) => {  // ВЫНЕСТИ В СЕЛЕКТОР
        let time = new Date(item.time)
        const current_time = new Date();

        // Определим даты для понятий вчера, сегодня, на этой неделе и в этом месяце
        const yesterday = new Date(current_time);
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0);

        const start_of_today = new Date(current_time);
        start_of_today.setHours(0, 0, 0, 0);

        const start_of_week = new Date(current_time);
        start_of_week.setDate(start_of_week.getDate() - start_of_week.getDay());
        start_of_week.setHours(0, 0, 0, 0);

        const start_of_month = new Date(current_time.getFullYear(), current_time.getMonth(), 1, 0, 0, 0, 0);

        if (time >= start_of_today) {
            todayCollection.push(item);
        } else if (time >= yesterday) {
            yesterdayCollection.push(item);
        } else if (time >= start_of_week) {
            weekCollection.push(item);
        } else if (time >= start_of_month) {
            monthCollection.push(item);
        } else {
            laterCollection.push(item)
        }
    })
    if (todayCollection.length > 0) finalCollection.push("Сегодня", ...todayCollection)
    if (yesterdayCollection.length > 0) finalCollection.push("Вчера", ...yesterdayCollection)
    if (weekCollection.length > 0) finalCollection.push("На этой неделе", ...weekCollection)
    if (monthCollection.length > 0) finalCollection.push("В этом месяце", ...monthCollection)
    if (laterCollection.length > 0) finalCollection.push("Ранее", ...laterCollection)

    if (collection.length === 0) {
        return (
            <>
                <HeaderCollection
                    header={header}
                    onSwitchBack={onSwitchBack}
                    counterAccept={counterAccept}
                    counterWant={counterWant}
                    fixed={true}
                />
                <EmptyCollectionPage readonly={readonly} switcher={switcher} />
            </>
        )
    }

    return (
        <div className={cls.Container}>
            <HeaderCollection
                header={header}
                onSwitchBack={onSwitchBack}
                counterAccept={counterAccept}
                counterWant={counterWant}
                fixed={true}
            />
            {finalCollection.length ? <>
                <GridElement collection={finalCollection} switcher={switcher} readonly={readonly} />
            </> : null}
        </div>

    )
}

export default GridCollection