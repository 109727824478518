export const getUid = (state) => state.auth.uid

export const getAcceptedMoments = (state) => state.auth.acceptedMoments
export const getWantedMoments = (state) => state.auth.wantedMoments
export const getRejectedMoments = (state) => state.auth.rejectedMoments
export const getCreatedMoments = (state) => state.auth.createdMoments
export const getPinnedMoments = (state) => state.auth.pinnedMoments

export const getAllCards = (state) => state.auth.allCards
export const getCards = (state) => state.auth.cards
export const getStatus = (state) => state.auth.status
export const getError = (state) => state.auth.error
export const getDirection = (state) => state.auth.direction
export const getArrayCard = (state) => state.auth.arrayCard
export const getIsLoaded = (state) => state.auth.isLoaded