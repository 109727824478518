import cls from './Cross.module.scss'
import cross from 'shared/assets/icons/cross-32.svg'

export const Cross = (props) => {
    const { onClose } = props

    return (
        <div className={cls.Cross} onClick={onClose}>
            <img src={cross} alt='cross'></img>
        </div>
    )
}