export const randomColor = () => {
    const colorEnum = { // Список цветов карточек
        FIRST: 'First',
        SECOND: 'Second',
        THIRD: 'Third',
        FOURTH: 'Fourth',
        FIFTH: 'Fifth',
        SIXTH: 'Sixth',
        SEVENTH: 'Seventh',
        EIGHTH: 'Eighth',
        NINTH: 'Ninth',
        TENTH: 'Tenth',
        ELEVENTH: 'Eleventh',
        TWELFTH: 'Twelfth',
        THIRTEENTH: 'Thirteenth',
        FOURTEENTH: 'Fourteenth',
    }

    return Object.keys(colorEnum)[Math.floor(Math.random() * Object.keys(colorEnum).length)]
}
