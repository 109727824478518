import { Text, TextAlign } from "shared/ui/Text/Text"
import cls from "./WantListItem.module.scss"
import check from "shared/assets/icons/check-32.svg"
import inside from "shared/assets/icons/inside.svg"
import { useDispatch } from "react-redux"
import { authActions } from "features/anonymousAuth"
import { useEffect, useState } from "react"
import { animated } from '@react-spring/web'
import { Menu } from "widgets/Menu/Menu"
import star from "shared/assets/icons/star-32.svg"
import cross from "shared/assets/icons/cross-32.svg"
import { classNames } from "shared/lib/classNames/classNames"
import { MenuFloat } from "widgets/MenuFloat/MenuFloat"
import { parser } from "shared/lib/parser/parser"

export const WantListItem = (props) => {
    const { readonly, text, img, id, style, toAcceptedMoment, toWantedMoment, settingsClick, comparison, toRejectedMoment, toPinnedMoment, fromPinnedMoment, pinned } = props

    const [icon, setIcon] = useState()
    const [disabled, setDisabled] = useState(false)

    const [settings, setSettings] = useState(false)

    const onSettings = () => {
        setSettings(true)
    }

    const onCloseSettings = () => {
        setSettings(false)
    }

    useEffect(() => {
        if (comparison === 'accepted') {
            setIcon(check)
        } else if (comparison === 'wanted') {
            setIcon(star)
        } else if (comparison === 'rejected') {
            setIcon(cross)
        } else if (comparison === undefined) {
            setIcon(star)
            setDisabled(true)
        }
    }, [comparison])

    const mods = {
        [cls[comparison]]: true,
    }

    let parsedText = parser(text)

    if (readonly) {
        return (
            <>
                <animated.div style={style} className={cls.WantListItem}>
                    <img className={cls.Img} alt="img" src={img}></img>
                    <Text align={TextAlign.LEFT} className={cls.Text}><b>{parsedText[0]}</b> {parsedText[1]}</Text>
                    <>
                        <button
                            onClick={disabled ? () => {
                                toWantedMoment(id)
                            } : null}
                            className={classNames(cls.Button, mods, [])}
                        >
                            <img alt="check" className={cls.Check} src={icon}></img>
                        </button>
                    </>
                </animated.div>
                <div className={cls.Dots}></div>
            </>
        )
    }

    return (
        <>
            <animated.div style={style} className={cls.WantListItem}>
                {settings ? 
                <Menu 
                    isPinned={pinned}
                    cardId={id}
                    onCloseSettings={onCloseSettings}
                    toRejectedMoment={toRejectedMoment}
                    toPinnedMoment={toPinnedMoment}
                    fromPinnedMoment={fromPinnedMoment}
                /> 
                : null}
                <img className={cls.Img} alt="img" src={img}></img>
                <Text align={TextAlign.LEFT} className={cls.Text}><b>{parsedText[0]}</b> {parsedText[1]}</Text>
                <>
                    <button onClick={() => toAcceptedMoment(id)} className={cls.AcceptButton}>
                        <img alt="check" className={cls.Check} src={check}></img>
                    </button>
                    <button onClick={onSettings} className={cls.SettingsButton}>
                        <img className={cls.Inside} alt="check" src={inside}></img>
                    </button>
                </>
            </animated.div>
            <div className={cls.Dots}></div>
        </>
    )
}