import { useEffect, useRef, useState } from "react"
import { WantListItem } from "widgets/WantListItem/ui/WantListItem"
import { useTransition } from "@react-spring/web"
import { authActions } from "features/anonymousAuth"
import { useDispatch, useSelector } from "react-redux"
import { Menu } from "widgets/Menu/Menu"
import cls from "./WantContainer.module.scss"
import { getPinnedMoments } from "features/anonymousAuth/model/selectors/getSelectors"
import { getOtherPinnedCardData, getPinnedCardData } from "app/providers/LoadDataProvider/ui/selectors"
import { Text, TextAlign, TextColor, TextSize, TextWeight } from "shared/ui/Text/Text"
import pin from "shared/assets/icons/pin.svg"
import { MenuFloat } from "widgets/MenuFloat/MenuFloat"
import { EmptyWantContainer } from "../EmptyWantContainer/EmptyWantContainer"
import { Credits } from "widgets/Credits/Credits"
import { BottomButton } from "./BottomButton/BottomButton"

export const WantContainer = (props) => {
    const { readonly, wantedCardData, myAllMoments } = props
    const dispatch = useDispatch()

    const [wantedData, setWantedData] = useState([])
    // const [pagArr, setPagArr] = useState([])
    const counterRef = useRef(false)

    const [pinnedData, setPinnedData] = useState([])
    // const [isPinned, setIsPinned] = useState(false)
    // const [y, setY] = useState(0)
    const [cardId, setCardId] = useState(null)
    const pinnedMoments = useSelector(getPinnedMoments)
    const pinnedCardData = useSelector(getPinnedCardData)

    const otherPinnedCardData = useSelector(getOtherPinnedCardData)
    // console.log(otherPinnedCardData)

    const intersectRef = useRef(null)

    useEffect(() => {
        // console.log(wantedCardData)
        let todayCollection = []
        let yesterdayCollection = []
        let weekCollection = []
        let monthCollection = []
        let laterCollection = []
        let finalCollection = []

        let ids = []
        pinnedMoments.map((item) => {
            ids.push(item.id)
        })

        let otherIds = []
        otherPinnedCardData.map((item) => {
            otherIds.push(item.id)
        })
  

        wantedCardData.map((item) => {  // ВЫНЕСТИ В СЕЛЕКТОР
            // console.log(pinnedMoments, item)
            if (!ids.includes(item.id) && !readonly) { 
                let time = new Date(item.time)
                const current_time = new Date();
    
                // Определим даты для понятий вчера, сегодня, на этой неделе и в этом месяце
                const yesterday = new Date(current_time);
                yesterday.setDate(yesterday.getDate() - 1);
                yesterday.setHours(0, 0, 0, 0);
    
                const start_of_today = new Date(current_time);
                start_of_today.setHours(0, 0, 0, 0);
    
                const start_of_week = new Date(current_time);
                start_of_week.setDate(start_of_week.getDate() - start_of_week.getDay());
                start_of_week.setHours(0, 0, 0, 0);
    
                const start_of_month = new Date(current_time.getFullYear(), current_time.getMonth(), 1, 0, 0, 0, 0);
    
                if (time >= start_of_today) {
                    todayCollection.push(item);
                } else if (time >= yesterday) {
                    yesterdayCollection.push(item);
                } else if (time >= start_of_week) {
                    weekCollection.push(item);
                } else if (time >= start_of_month) {
                    monthCollection.push(item);
                } else {
                    laterCollection.push(item)
                }
            } else if (!otherIds.includes(item.id) && readonly) { // какая-то жесть уже пошла (дублирование закрепа и обычки была, вынести в отдельную функцию без дублирования кода)
                let time = new Date(item.time)
                const current_time = new Date();
    
                // Определим даты для понятий вчера, сегодня, на этой неделе и в этом месяце
                const yesterday = new Date(current_time);
                yesterday.setDate(yesterday.getDate() - 1);
                yesterday.setHours(0, 0, 0, 0);
    
                const start_of_today = new Date(current_time);
                start_of_today.setHours(0, 0, 0, 0);
    
                const start_of_week = new Date(current_time);
                start_of_week.setDate(start_of_week.getDate() - start_of_week.getDay());
                start_of_week.setHours(0, 0, 0, 0);
    
                const start_of_month = new Date(current_time.getFullYear(), current_time.getMonth(), 1, 0, 0, 0, 0);
    
                if (time >= start_of_today) {
                    todayCollection.push(item);
                } else if (time >= yesterday) {
                    yesterdayCollection.push(item);
                } else if (time >= start_of_week) {
                    weekCollection.push(item);
                } else if (time >= start_of_month) {
                    monthCollection.push(item);
                } else {
                    laterCollection.push(item)
                } 
            }
        })
        if (todayCollection.length > 0) finalCollection.push("Сегодня", ...todayCollection)
        if (yesterdayCollection.length > 0) finalCollection.push("Вчера", ...yesterdayCollection)
        if (weekCollection.length > 0) finalCollection.push("На этой неделе", ...weekCollection)
        if (monthCollection.length > 0) finalCollection.push("В этом месяце", ...monthCollection)
        if (laterCollection.length > 0) finalCollection.push("Ранее", ...laterCollection)
        finalCollection.sort((a, b) => b.time - a.time)

        setWantedData(finalCollection
            // .splice(0, 10)
            )
            // .filter((item) => !pinnedMoments.includes(item.id)) 
        // setPagArr([...finalCollection])

        // setShowedCollection(stateCollection.splice(0, 10))
        // setPagArr([...stateCollection])

        if (readonly) {
            setPinnedData(otherPinnedCardData)
        } else {
            setPinnedData(pinnedCardData)
        }
        
    }, [])

    // пагинация
    // useEffect(() => {
    //     const options = {
    //         rootMargin: "0px",
    //         thresHold: 0.3
    //     }

    //     const observer = new IntersectionObserver((entries, observer) => {
    //         if (entries[0].isIntersecting && pagArr.length > 0) {
    //             console.log('пересечен')
    //             console.log(pagArr)
    //             setWantedData([...wantedData, ...pagArr])
    //         }
    //     }, options)
    //     if (intersectRef.current) observer.observe(intersectRef.current)

    //     return () => {
    //         if (intersectRef.current) observer.unobserve(intersectRef.current)
    //     }
    // }, [pagArr])

    const toAcceptedMoment = (id) => {
        dispatch(authActions.toAcceptedMoment(id))
        setWantedData([...wantedData.filter((item) => item.id !== id)])
        setPinnedData([...pinnedData.filter((item) => item.id !== id)])
        // setPagArr([...pagArr.filter((item) => item.id !== id)])
    }

    const toWantedMoment = (id) => {
        dispatch(authActions.toWantedMoment(id))

        if (pinnedData.find((item) => item.id == id)) {
            console.log(true)
            pinnedData.map((item) => {
                if (item.id && item.id == id) {
                    item.comparison = 'wanted'
                }
            })
        } else {
            wantedData.map((item) => {
                if (item.id && item.id == id) {
                    item.comparison = 'wanted'
                }
            })
        }
        // setWantedData(newWantedData)
        // console.log(newWantedData)
    }

    // const settingsClick = (e, id) => {
    //     if (pinnedMoments.includes(id)) {
    //         setIsPinned(true)
    //     } else {
    //         setIsPinned(false)
    //     }
    //     const element = e.target.getBoundingClientRect()
    //     // setY(element.y + 340)
    //     console.log(id)
    //     setCardId(id)
    // }

    // const onCloseSettings = () => {
    //     setY(0)
    // }

    const toRejectedMoment = (id) => {
        dispatch(authActions.toRejectedMoment(id))
        setWantedData([...wantedData.filter((item) => item.id !== id)])
        setPinnedData([...pinnedData.filter((item) => item.id !== id)])
        // setPagArr([...pagArr.filter((item) => item.id !== id)])
    }

    const toPinnedMoment = (id) => {
        dispatch(authActions.toPinnedMoment(id))
        setPinnedData([...wantedData.filter((item) => item.id == id), ...pinnedData])
        setWantedData([...wantedData.filter((item) => item.id !== id)])
        // setPagArr([...pagArr.filter((item) => item.id !== id)])
    }

    const fromPinnedMoment = (id) => {
        dispatch(authActions.fromPinnedMoment(id))
        setPinnedData([...pinnedData.filter((item) => item.id !== id)])
        setWantedData([...wantedData, ...wantedData.filter((item) => item.id == id)].sort((a, b) => b.time - a.time))
    }

    const transitions = useTransition(wantedData, {  //анимация
        from: { opacity: 0, transform: 'translate3d(0,40px,0)' },
        enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
        leave: { opacity: 0, transform: 'translate3d(0, -40px,0)' },
        config: { tension: 320, friction: 20 },
        trail: 150,
    })

    const transitionsForPinned = useTransition(pinnedData, {
        from: { opacity: 0, transform: 'translate3d(0,40px,0)' },
        enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
        leave: { opacity: 0, transform: 'translate3d(0, -40px,0)' },
        config: { tension: 320, friction: 20 },
        trail: 150,
    })

    return (
        <div className={cls.WantContainer}>
            {wantedData.length !== 0 || pinnedData.length !== 0 ? 
            <>
                {pinnedData.length ?
                    <Text
                        weight={TextWeight.SBOLD}
                        color={TextColor.TERBLACK}
                        align={TextAlign.LEFT}
                        size={TextSize.S}
                        className={cls.Pinned}
                    >
                        <div className={cls.PinnedTextWrapper}>
                            Закреплено
                            <img className={cls.Icon} src={pin} alt="icon"></img>
                        </div>
                    </Text> :
                    null}
                {/* {transitionsForPinned((style, item) => {
                    return (
                        <WantListItem
                            style={style}
                            key={item.id}
                            text={item.text}
                            img={item.img}
                            id={item.id}
                            comparison={item.comparison}
                            toAcceptedMoment={toAcceptedMoment}
                            toWantedMoment={toWantedMoment}
                            settingsClick={settingsClick}
                            readonly={readonly}
                        />
                    )
                })} */}
                {pinnedData.map((item) => {
                    return (
                        <WantListItem
                            pinned={true}
                            key={item.id}
                            text={item.text}
                            img={item.img}
                            id={item.id}
                            comparison={item.comparison}
                            toAcceptedMoment={toAcceptedMoment}
                            toWantedMoment={toWantedMoment}
                            // settingsClick={settingsClick}
                            fromPinnedMoment={fromPinnedMoment}
                            toRejectedMoment={toRejectedMoment}
                            readonly={readonly}
                    />
                    )
                })}
                {/* {transitions((style, item) => {
                    if (typeof (item) === "string") {
                        return (
                            <Text
                                weight={TextWeight.SBOLD}
                                color={TextColor.TERBLACK}
                                align={TextAlign.LEFT}
                                size={TextSize.S}
                                className={cls.Pinned}
                            >
                                {item}
                            </Text>
                        )
                    } else {
                        return (
                            <WantListItem
                                readonly={readonly}
                                style={style}
                                key={item.id}
                                text={item.text}
                                img={item.img}
                                id={item.id}
                                comparison={item.comparison}
                                toAcceptedMoment={toAcceptedMoment}
                                toWantedMoment={toWantedMoment}
                                settingsClick={settingsClick}
                            />
                        )
                    }
                })} */}
                {wantedData.map((item) => {
                    if (typeof (item) === "string") {
                        return (
                            <Text
                                weight={TextWeight.SBOLD}
                                color={TextColor.TERBLACK}
                                align={TextAlign.LEFT}
                                size={TextSize.S}
                                className={cls.Pinned}
                                key={item}
                            >
                                {item}
                            </Text>
                        )
                    } else {
                        return (
                            <WantListItem
                                readonly={readonly}
                                // style={style}
                                key={item.id}
                                text={item.text}
                                img={item.img}
                                id={item.id}
                                comparison={item.comparison}
                                toAcceptedMoment={toAcceptedMoment}
                                toWantedMoment={toWantedMoment}
                                // settingsClick={settingsClick}
                                toRejectedMoment={toRejectedMoment}
                                toPinnedMoment={toPinnedMoment}
                                fromPinnedMoment={fromPinnedMoment}
                            />
                        )
                    }
                })}
                <div style={{height: '30px', width: '100%'}} ref={intersectRef}></div>
                <BottomButton readonly={readonly}/>
                <Credits mode={readonly ? 'short' : null} style={myAllMoments.length === 0 && readonly ? {marginBottom: '76px'} : null}/>
            </> : 
            <>
                <EmptyWantContainer readonly={readonly}/>
                <Credits 
                    className={readonly ? cls.CreditsShort : null} 
                    mode={readonly ? 'short' : null}
                    style={myAllMoments.length === 0 && readonly ? {marginBottom: '76px'} : null}
                />
            </>}
        </div>
    )
}