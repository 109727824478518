import check from 'shared/assets/icons/check-24.svg'
import star from 'shared/assets/icons/star-24.svg'
import search from 'shared/assets/icons/search-24.svg'
import cross from 'shared/assets/icons/cross-24.svg'
import cls from './Round.module.scss'
import { classNames } from 'shared/lib/classNames/classNames'

//применить classNames

export const RoundStatus = {
    ACCEPTED: 'accepted',
    WANTED: 'wanted',
    REJECTED: 'rejected',
    SEARCH: 'search'
}

export const Round = (props) => {
    const { status, pos, changeState, className, disabledRound, readonly } = props
    let src

    if (status === 'accepted') {
        src = check
    } else if (status === 'wanted') {
        src = star
    } else if (status === 'search') {
        src = search
    } else if (status === 'rejected') {
        src = cross
    }

    const mods = {
        [cls[status]]: true,
        [cls.static]: pos,
        [cls.disabled]: disabledRound,
        [cls.readonly]: readonly,
    }

    return (
        <div onClick={changeState}
            //  className={`${cls.Round} ${status === 'wanted' ? cls.Wanted : ''} ${pos ? cls.Static : ''}`}
            className={classNames(cls.Round, mods, [className])}
        >
            <img className={cls.Icon} alt="icon" src={src}></img>
        </div>
    )
}