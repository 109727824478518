import { Button } from 'shared/ui/Button'
import cls from './TextEditor.module.scss'
import { classNames } from 'shared/lib/classNames/classNames'
import cross from 'shared/assets/icons/cross-32.svg'

export const TextEditor = (props) => {
    const {
        onClose,
        onCloseTextEditor,
        counter,
        warning,
        onChangeText,
        text
    } = props

    return (
        <div className={cls.TextEditor} >
            <div className={cls.ButtonContainer}>
                <Button onClick={onClose} className={cls.CloseTextButton}>
                    <img style={{ 'pointerEvents': 'none', 'width': '24px' }} src={cross} alt='cross'></img>
                </Button>
                <Button onClick={onCloseTextEditor} className={cls.OpenTextButton} text={'Готово'}></Button>
            </div>
            <div className={cls.TextAreaContainer}>
                <div id='counter' className={classNames(cls.Counter, {}, [])}>{counter}{warning}</div>
                <textarea
                    autoFocus
                    className={cls.Input}
                    placeholder='Например: «Завести блог» или «Научиться петь»'
                    onChange={(e) => onChangeText(e)}
                    value={text}
                    enterKeyHint="done"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            document.activeElement.blur();
                        }
                    }}
                >
                </textarea>
            </div>
            <div className={cls.TextEditorBackground}></div>
        </div>
    )
}