// selectors.js

import { createSelector } from '@reduxjs/toolkit';
import { getAcceptedMoments, getAllCards, getRejectedMoments, getWantedMoments } from 'features/anonymousAuth';
import { getCreatedMoments, getPinnedMoments } from 'features/anonymousAuth/model/selectors/getSelectors';

// export const selectCards = (state) => state.auth.acceptedMoments;

export const getCollections = (state) => state.collection.collections;

export const getOtherWantedMoments = (state) => state.otherProfileData.wantedMoments
export const getOtherAcceptedMoments = (state) => state.otherProfileData.acceptedMoments
export const getOtherRejectedMoments = (state) => state.otherProfileData.rejectedMoments
export const getOtherCreatedMoments = (state) => state.otherProfileData.createdMoments
export const getOtherPinnedMoments = (state) => state.otherProfileData.pinnedMoments

export const getOtherId = (state) => state.otherProfileData.id

// Селектор для рассчета карточек, входящих в конкретную коллекцию

const selectCardsInCollection = (acceptedMoments, wantedMoments, rejectedMoments, collection, allCards) => {
    const allMoments = acceptedMoments.concat(wantedMoments, rejectedMoments)
    allMoments.sort((a, b) => a.time - b.time)
    let graduation = [0, 1, 3, 5, 7, 10]

    let parsedCollections = JSON.parse(JSON.stringify(collection))

    parsedCollections.map((item) => {
        item.finded = []
        item.finded = allMoments.filter((element) => {
            if (item.idArray.includes(element.id)) {
                return true
            }
        })
        item.accepted = []
        item.accepted = acceptedMoments.filter((element) => {
            if (item.idArray.includes(element.id)) {
                return true
            }
        })
    })

    parsedCollections.map((item) => {
        if (item.finded.length != 0) {
            item.lastFinded = allCards.find((element) => {
                return element.id === item.finded[item.finded.length - 1].id
            })
        } else {
            item.lastFinded = null
        }

        if (item.finded.length >= 2) {
            item.secondFinded = allCards.find((element) => {
                return element.id === item.finded[item.finded.length - 2].id
            })
        } else {
            item.secondFinded = null
        }
        // item.received = false
        // if (item.accepted.length >= item.goal) {
        //     item.received = true
        // }
    })

    parsedCollections.map((item) => {
        let allCards2 = JSON.parse(JSON.stringify(allCards))

        item.findedCardData = allCards2.filter((elem) => {  // расчет найденных карточек
            let ids = []
            let allIds = []
            item.finded.map((elem) => {
                ids.push(elem.id)
            })

            item.idArray
                .map((elem) => {
                    allIds.push(elem)
                })

            item.lockedIds = allIds.filter((elem) => {
                return !ids.includes(elem)
            })


            if (ids.includes(elem.id)) {
                if (acceptedMoments.map(elem => elem.id).includes(elem.id)) {
                    elem.checked = 'accepted'
                } else if (wantedMoments.map(elem => elem.id).includes(elem.id)) {
                    elem.checked = 'wanted'
                } else if (rejectedMoments.map(elem => elem.id).includes(elem.id)) {
                    elem.checked = 'rejected'
                }
                elem.time = item.finded.find((element) => element.id === elem.id).time

                return (ids.includes(elem.id))
            }
        })

        // item.findedCardData.sort((a, b) => (a === b) ? 0 : a ? -1 : 1)
        item.findedCardData.sort((a, b) => b.time - a.time)

        allCards2.map((elem) => {
            if (item.lockedIds.includes(elem.id)) {
                elem.locked = true
                item.findedCardData.push(elem)
            }
        })
        // if (item.findedCardData.length < item.idArray.length) { // добавление заблокированных карточек
        //     let quantityLocked = item.idArray.length - item.findedCardData.length
        //     for (let i = 1; i <= quantityLocked; i++) {
        //         item.findedCardData.push({ locked: true, id: 100 + i })
        //     }
        // }
    })


    parsedCollections.map((item) => {
        item.level = 0
        item.progress = 0
        item.max = false

        let sum = 0
        if (item.accepted.length === item.idArray.length) {
            item.max = true
            item.progress = 100
        } else {
            for (let i = 1; i < graduation.length; i++) {
                sum += graduation[i]
                if (item.accepted.length >= sum) {
                    if (i === 5) {
                        item.level += Math.floor((item.accepted.length - sum) / 10)
                        sum += 10 * Math.floor((item.accepted.length - sum) / 10)
                    }
                    item.level++
                } else {
                    sum -= graduation[i]
                    break
                }
            }
            if (graduation[item.level] !== 0) {
                if (graduation[item.level + 1]) {
                    item.progress = Math.ceil((item.accepted.length - sum) / graduation[item.level + 1] * 100)
                    item.levelGoal = graduation[item.level + 1]
                } else {
                    item.progress = Math.ceil((item.accepted.length - sum) / 10 * 100)
                    item.levelGoal = 10
                }
                item.progressCount = item.accepted.length - sum
            } else {
                // console.log(sum)
                // item.progress = Math.ceil((item.accepted.length - sum) / 10 * 100)
                item.progressCount = 0
                item.levelGoal = 1
            }
            if (item.idArray.length - sum < item.levelGoal) { // когда карточек осталось меньше чем для достижения дефолтного лвла
                item.levelGoal = item.idArray.length - sum
                item.nextLevelMax = true
            }

        }
    })

    // console.log(parsedCollections)

    parsedCollections.sort((a, b) => b.finded.length - a.finded.length)
    parsedCollections.sort((a, b) => b.accepted.length - a.accepted.length)
    // parsedCollections.sort((a, b) => b.max - a.max)


    return parsedCollections
}

export const getCreatedCardData = createSelector(
    getAcceptedMoments, getWantedMoments, getRejectedMoments, getCreatedMoments,
    (acceptedMoments, wantedMoments, rejectedMoments, createdMoments) => {
        console.log(acceptedMoments)
        let createdCardData = JSON.parse(JSON.stringify(createdMoments))
        console.log(createdCardData)
        createdCardData.map((item) => {
            let acceptedId = acceptedMoments.map(item => item.id)
            let wantedId = wantedMoments.map(item => item.id)
            let rejectedId = rejectedMoments.map(item => item.id)
            if (acceptedId.includes(item.id)) {
                item.checked = 'accepted'
            } else if (wantedId.includes(item.id)) {
                item.checked = 'wanted'
            } else if (rejectedId.includes(item.id)) {
                item.checked = 'rejected'
            } else {
                item.blocked = 'blockedWhite'
            }
        })

        createdCardData.sort((a, b) => {
            if (a.blocked === undefined) {
                return -1
            }
        })

        return createdCardData
    }
)

export const getSelectCardsInCollection = createSelector(
    getAcceptedMoments, getWantedMoments, getRejectedMoments, getCollections, getAllCards,
    (acceptedMoments, wantedMoments, rejectedMoments, collection, allCards) => {
        return selectCardsInCollection(acceptedMoments, wantedMoments, rejectedMoments, collection, allCards)
    }
);


export const getSelectCardsInOtherCollection = createSelector(
    getOtherAcceptedMoments, getOtherWantedMoments, getOtherRejectedMoments, getCollections, getAllCards,
    (acceptedMoments, wantedMoments, rejectedMoments, collection, allCards) => {
        return selectCardsInCollection(acceptedMoments, wantedMoments, rejectedMoments, collection, allCards)
    }
);

export const getWantedCardData = createSelector(
    getWantedMoments, getAllCards,
    (wantedMoments, allCards) => {
        let allCards2 = JSON.parse(JSON.stringify(allCards))

        let wantedCardData = allCards2.filter((item) => {
            let ids = []
            wantedMoments.map((item) => {
                ids.push(item.id)
            })
            if (ids.includes(item.id)) {
                item.checked = 'wanted'
                item.time = wantedMoments.find((element) => element.id === item.id).time

                return (ids.includes(item.id))
            }
        })

        return wantedCardData
    }
);

export const getAcceptedCardData = createSelector(
    getAcceptedMoments, getAllCards,
    (acceptedMoments, allCards) => {

        let allCards2 = JSON.parse(JSON.stringify(allCards))

        let acceptedCardData = allCards2.filter((item) => {
            let ids = []
            acceptedMoments.map((item) => {
                ids.push(item.id)
            })
            if (ids.includes(item.id)) {
                item.checked = 'accepted'
                item.time = acceptedMoments.find((element) => element.id === item.id).time

                return (ids.includes(item.id))
            }
        })

        return acceptedCardData
    }
);

export const getPinnedCardData = createSelector(
    getPinnedMoments, getAllCards,
    (pinnedMoments, allCards) => {

        let allCards2 = JSON.parse(JSON.stringify(allCards))

        let ids = []
        pinnedMoments.map((item) => {
            ids.push(item.id)
        })

        let pinnedCardData = allCards2.filter((item) => {
            if (ids.includes(item.id)) {
                item.time = pinnedMoments.find((element) => element.id === item.id).time
                return true
            }
        })

        pinnedCardData.sort((a, b) => b.time - a.time)

        return pinnedCardData
    }
);

export const getOtherAcceptedCardData = createSelector(
    getOtherAcceptedMoments, getAllCards,
    (acceptedMoments, allCards) => {

        console.log('getotheraccepted')
        let allCards2 = JSON.parse(JSON.stringify(allCards))

        let acceptedCardData = allCards2.filter((item) => {
            let ids = []
            acceptedMoments.map((item) => {
                ids.push(item.id)
            })

            if (ids.includes(item.id)) {
                item.checked = 'accepted'
                item.time = acceptedMoments.find((element) => element.id === item.id).time

                return (ids.includes(item.id))
            }
        })

        return acceptedCardData
    }
);

export const getOtherWantedCardData = createSelector(
    getOtherWantedMoments, getAllCards,
    (wantedMoments, allCards) => {

        console.log('getotherwanted')
        let allCards2 = JSON.parse(JSON.stringify(allCards))

        let wantedCardData = allCards2.filter((item) => {
            let ids = []
            wantedMoments.map((item) => {
                ids.push(item.id)
            })
            if (ids.includes(item.id)) {
                item.checked = 'wanted'
                item.time = wantedMoments.find((element) => element.id === item.id).time

                return (ids.includes(item.id))
            }
        })

        return wantedCardData
    }
);

export const getOtherCreatedCardData = createSelector(
    getAcceptedMoments, getWantedMoments, getRejectedMoments, getOtherCreatedMoments, getAllCards, getOtherId,
    (acceptedMoments, wantedMoments, rejectedMoments, createdMoments, allCards, getOtherId) => {

        createdMoments = allCards.filter((item) => {
            if (item.userId == getOtherId) {
                return true
            }
        })

        let createdCardData = JSON.parse(JSON.stringify(createdMoments))
        createdCardData.map((item) => {
            let acceptedId = acceptedMoments.map(item => item.id)
            let wantedId = wantedMoments.map(item => item.id)
            let rejectedId = rejectedMoments.map(item => item.id)
            if (acceptedId.includes(item.id)) {
                item.checked = 'accepted'
            } else if (wantedId.includes(item.id)) {
                item.checked = 'wanted'
            } else if (rejectedId.includes(item.id)) {
                item.checked = 'rejected'
            } else {
                item.blocked = 'blockedWhite'
            }
        })

        createdCardData.sort((a, b) => {
            if (a.blocked === undefined) {
                return -1
            }
        })

        return createdCardData
    }
)

export const getOtherPinnedCardData = createSelector(
    getOtherPinnedMoments, getAllCards, getAcceptedMoments, getWantedMoments, getRejectedMoments,
    (pinnedMoments, allCards, myAcceptedMoments, myWantedMoments, myRejectedMoments) => {
        if (pinnedMoments) {
            let allCards2 = JSON.parse(JSON.stringify(allCards))

            let acceptedId = myAcceptedMoments.map(item => item.id)
            let wantedId = myWantedMoments.map(item => item.id)
            let rejectedId = myRejectedMoments.map(item => item.id)    

            let ids = []
            pinnedMoments.map((item) => {
                ids.push(item.id)
            })

            let pinnedCardData = allCards2.filter((item) => {
                if (ids.includes(item.id)) {
                    item.time = pinnedMoments.find((element) => element.id === item.id).time

                    return true
                }
            })

            pinnedCardData.map((item) => {
                if (acceptedId.includes(item.id)) {
                    item.comparison = 'accepted'
                } else if (wantedId.includes(item.id)) {
                    item.comparison = 'wanted'
                } else if (rejectedId.includes(item.id)) {
                    item.comparison = 'rejected'
                }
            })

            pinnedCardData.sort((a, b) => b.time - a.time)

            return pinnedCardData
        } else {
            return []
        }

    }
);

export const getOtherWantedCardDataWithMyPicks = createSelector(
    getOtherWantedMoments, getAllCards, getAcceptedMoments, getWantedMoments, getRejectedMoments,
    (wantedMoments, allCards, myAcceptedMoments, myWantedMoments, myRejectedMoments) => {

        console.log(myAcceptedMoments)
        let allCards2 = JSON.parse(JSON.stringify(allCards))

        let acceptedId = myAcceptedMoments.map(item => item.id)
        let wantedId = myWantedMoments.map(item => item.id)
        let rejectedId = myRejectedMoments.map(item => item.id)

        let wantedCardData = allCards2.filter((item) => {
            let ids = []
            wantedMoments.map((item) => {
                ids.push(item.id)
            })
            if (ids.includes(item.id)) {
                item.checked = 'wanted'
                item.time = wantedMoments.find((element) => element.id === item.id).time

                return (ids.includes(item.id))
            }
        })

        wantedCardData.map((item) => {
            if (acceptedId.includes(item.id)) {
                item.comparison = 'accepted'
            } else if (wantedId.includes(item.id)) {
                item.comparison = 'wanted'
            } else if (rejectedId.includes(item.id)) {
                item.comparison = 'rejected'
            }
        })

        return wantedCardData
    }
)