import { Text, TextAlign, TextColor, TextSize } from "shared/ui/Text/Text"
import cls from "../FriendsPage.module.scss"

export const Nick = (props) => {
    const { copyNick, searchValidate, nickname, id } = props

    return (
        <Text
            className={cls.nicknameErrorText}
            color={searchValidate === 'error' ? TextColor.RED : TextColor.TERBLACK}
            size={TextSize.XS}
            align={TextAlign.LEFT}
        >
            Кстати, твой ник — <div onClick={() => copyNick(nickname ? `@${nickname}` : `@${id}`)} className={cls.Copynickname}>@{nickname ? nickname : id}</div>
        </Text>
    )
}
