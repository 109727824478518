import { Button } from 'shared/ui/Button'
import cls from './EmptyCollectionPage.module.scss'
import { useNavigate } from 'react-router-dom'
import whale from 'shared/assets/images/whale2.png'
import { Text } from 'shared/ui/Text/Text'

const EmptyCollectionPage = (props) => {
    const {switcher, readonly} = props

    const navigate = useNavigate()

    const onRedirect = () => {
        navigate('/')
    }

    const onRedirectToSuggest = () => {
        navigate('/suggest')
    }

    if (readonly) {
        return (
            <div className={cls.EmptyCollectionPage}>
                <img className={cls.Image} src={whale} alt="whale"></img>
                <div className={cls.Title}>Пока тут пусто</div>
            </div>
        )
    }
    
    if (switcher == 'created') {
        return (
            <div className={cls.EmptyCollectionPage}>
                <img className={cls.Image} src={whale} alt="whale"></img>
                <div className={cls.Title}>Список созданного пока пуст</div>
                <Text className={cls.Second}>Создай свою мечту и её увидят все пользователи TagLife!</Text>
                <Button className={cls.Button} onClick={onRedirectToSuggest} text={'Создать свою мечту'}></Button>
            </div>
        )
    }

    return (
        <div className={cls.EmptyCollectionPage}>
            <img className={cls.Image} src={whale} alt="whale"></img>
            <div className={cls.Title}>Список достижений пока пуст</div>
            <Text>Чтобы помнить о своих достижениях, отмечай мечты галочкой ✅</Text>
            <Button className={cls.Button} onClick={onRedirect} text={'Найти мечту'}></Button>
        </div>
    )
}

export default EmptyCollectionPage