import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    menuItem: '/',
    navVisible: false,
    newContent: false,
}

const navSlice = createSlice({
    name: "navSlice",
    initialState,
    reducers: {
        setVisible(state, action) {
            state.navVisible = action.payload
        },
        onActive(state, action) {
            state.menuItem = action.payload
        },
        setNewContent(state, action) {
            localStorage.setItem('newProfileContent', action.payload)
            state.newContent = action.payload
        }
    }
})

export default navSlice.reducer
export const { actions: navActions } = navSlice