import { classNames } from "shared/lib/classNames/classNames"
import cls from '../InstallWidget.module.scss'
import { Button } from "shared/ui/Button"
import whale from 'shared/assets/images/whale1.png'
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { navActions } from "entities/NavMenu"

export const Subscribe = (props) => {
    const { onSubscribe } = props
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(navActions.setVisible(false))
    }, [])

    return (
        <div className={classNames(cls.InstallWidget, {}, [])}>
            <div className={cls.ImageContainer}>
                <img className={cls.Image} alt='TagLife' src={whale}></img>
            </div>
            <div className={cls.Text}>Чтобы продолжить, нужно подписаться на наш телеграм</div>
            <Button className={cls.Button} onClick={onSubscribe} text={'Подписаться'}></Button>
        </div>
    )
}