import { Button } from "shared/ui/Button"
import { Text, TextSize, TextWeight } from "shared/ui/Text/Text"
import cls from "./EmptyWantContainer.module.scss"
import whale from "shared/assets/images/whale2.png"
import { useNavigate } from "react-router-dom"

export const EmptyWantContainer = (props) => {
    const {readonly} = props

    const navigate = useNavigate()

    const onRedirect = () => {
        navigate('/')
    }


    if (readonly) {
        return (
            <div className={cls.EmptyWantContainer}>
                <img src={whale} className={cls.Whale} alt="whale"></img>
                <Text 
                    className={cls.TitleText} 
                    weight={TextWeight.BOLD}
                    size={TextSize.XL}
                >
                    Пока тут пусто
                </Text>
            </div>
        )
    }

    return (
        <div className={cls.EmptyWantContainer}>
            <img src={whale} className={cls.Whale} alt="whale"></img>
            <Text 
                className={cls.TitleText} 
                weight={TextWeight.BOLD}
                size={TextSize.XL}
            >
                Список с мечтами пока пуст
            </Text>
            <Text
                className={cls.SecondText}
            >
                Чтобы не потерять интересные мечты, добавляй их к себе в профиль с помощью звёздочки ⭐
            </Text>
            <Button 
                className={cls.Button}
                text='Найти мечту'
                onClick={onRedirect}
            />
        </div>
    )
}