import { Round } from "shared/ui/Round"
import cls from "./ProgressContainer.module.scss"
import { Text, TextAlign, TextWeight } from "shared/ui/Text/Text"

export const ProgressContainer = (props) => {

    const { parsedCollection } = props

    return (
        <div className={cls.ProgressContainer}>
            <Round className={cls.RoundProgress} status="search" pos="static"></Round>
            <div className={cls.ProgressItem}>
                <div className={cls.ProgressItemWrapper}>
                    <Text
                        className={cls.ProgressCount}
                        align={TextAlign.LEFT}
                        weight={TextWeight.BOLD}
                    >
                        {parsedCollection.finded.length}
                    </Text>
                    <Text
                        className={cls.ProgressGoal}
                        align={TextAlign.LEFT}
                    >
                        / {parsedCollection.idArray.length}
                    </Text>
                </div>
                <Text
                    className={cls.TextReceived}
                    align={TextAlign.LEFT}
                >
                    Найдено
                </Text>
            </div>
            <Round className={cls.RoundProgress} status="accepted" pos="static"></Round>
            <div className={cls.ProgressItem}>
                <div className={cls.ProgressItemWrapper}>
                    <Text
                        className={cls.ProgressCount}
                        align={TextAlign.LEFT}
                        weight={TextWeight.BOLD}
                    >
                        {parsedCollection.accepted.length}
                    </Text>
                    <Text
                        className={cls.ProgressGoal}
                        align={TextAlign.LEFT}
                    >
                        / {parsedCollection.idArray.length}
                    </Text>
                </div>
                <Text
                    className={cls.TextReceived}
                    align={TextAlign.LEFT}
                >
                    Достигнуто
                </Text>
            </div>
        </div>
    )
}