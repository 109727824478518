import { useNavigate } from 'react-router-dom'
import cls from './PageError.module.scss'
import whale from 'shared/assets/images/whale1.png'

export const PageError = () => {
    const navigate = useNavigate()

    const reloadPage = () => {
        navigate('/')
        //eslint-disable-next-line no-restricted-globals
        location.reload()
    }

    return (
        <div className='main'>
            <img className={cls.Image} src={whale} alt="whale"></img>
            <div className={cls.Text}>Иногда нужно еще раз нажать, и все заработает</div>
            <button className={cls.Button} onClick={reloadPage}>Нажать</button>
            <div className={cls.SecondaryText}>Что-то по-прежнему не работает? <a href='https://t.me/TagLife_Help'>Напиши нам об этом</a></div>
        </div>
    )
}
