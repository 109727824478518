import { createSlice, current } from "@reduxjs/toolkit";
import { db } from "app/providers/FirebaseProvider/ui/FirebaseProvider";
import { fetchSuggestData } from "features/anonymousAuth/services/fetchSuggestData";
import { arrayRemove, arrayUnion, collection, doc, limit, orderBy, query, updateDoc, addDoc, getDocs, where } from "firebase/firestore";
import { randomColor } from "shared/lib/randomColor/randomColor";

const initialState = {
    suggests: [],
    suggest: [],
    arraySuggests: [],
    status: null,
    error: null,
    direction: [0, 0],
    currentSuggestId: null,
    currentText: '',
    currentImage: '',
    currentCollection: [],
    currentSpeak: '',
    currentId: null,
}

const postCard = (text, userId, image, speak, currentCollection) => {
    const ref = collection(db, 'cards')
    const q = query(ref, orderBy('id', 'desc'), limit(1))
    let lastId
    getDocs(q)
        .then((snapshot) => {
            lastId = snapshot.docs[0].data().id + 1
            console.log(lastId)
        })
        .then(() => {
            const requestsRef = collection(db, "cards");
            if (speak.length > 0) {
                addDoc(requestsRef, { id: lastId, text: text, userId: userId, img: image, speak });
            } else {
                addDoc(requestsRef, { id: lastId, text: text, userId: userId, img: image });
            }
        })

    // if (currentCollection.length > 0) {
    currentCollection.map((item) => {
        console.log(`запушилось в коллекцию ${item}`)
        const q = query(collection(db, "collections"), where("id", '==', item));
        getDocs(q)
            .then(querySnapshot => {
                querySnapshot.forEach(docs => {
                    const docRef = doc(db, "collections", docs.id);
                    updateDoc(docRef, { idArray: arrayUnion(lastId) })
                });
            })
            .catch(error => {
                console.error('Ошибка при выполнении запроса:', error);
            });
        // console.log(item)
    })
    // }
}


const setChecked = (suggestId) => {
    const q = query(collection(db, "suggests"), where("id", '==', suggestId));
    getDocs(q)
        .then(querySnapshot => {
            querySnapshot.forEach(docs => {
                const docRef = doc(db, "suggests", docs.id);
                updateDoc(docRef, { checked: true })
            });
        })
        .catch(error => {
            console.error('Ошибка при выполнении запроса:', error);
        });
}

const reducer = (state, action) => {
    state.arraySuggests.shift()
    // if (state.suggests.length !== 0) {
    state.arraySuggests.push(...state.suggests.splice(0, 1))
    state.suggest.pop()
    state.suggest.push(state.arraySuggests[0])
    if (state.arraySuggests[0]) {
        state.currentText = state.arraySuggests[0].text
        state.currentImage = state.arraySuggests[0].img
        state.currentId = state.arraySuggests[0].userId
        state.currentSuggestId = state.arraySuggests[0].id
    }
    // }

    state.direction = action.payload
    state.currentCollection = []
    state.currentSpeak = ''
    // state.isLoaded = false
}

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        acceptMoment(state, action) {
            let suggestId = state.currentSuggestId

            postCard(state.currentText, state.currentId, state.currentImage, state.currentSpeak, state.currentCollection)

            reducer(state, action)
            setChecked(suggestId)
        },
        rejectMoment(state, action) {
            setChecked(state.currentSuggestId)
            reducer(state, action)
        },
        changeCurrentText(state, action) {
            state.currentText = action.payload
        },
        changeCurrentImg(state, action) {
            state.currentImage = action.payload
        },
        changeCurrentCollection(state, action) {
            if (state.currentCollection.includes(action.payload)) {
                state.currentCollection = state.currentCollection.filter((item) => action.payload !== item)
            } else {
                state.currentCollection.push(action.payload)
            }
        },
        changeCurrentSpeak(state, action) {
            state.currentSpeak = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                fetchSuggestData.pending, (state) => {
                    state.status = 'loading'
                    state.error = null
                }
            )
            .addCase(
                fetchSuggestData.fulfilled, (state, action) => {
                    state.suggests = action.payload.slice(1).filter((item) => {
                        return !item.checked && item.userId
                    })

                    state.arraySuggests = state.suggests.splice(0, 3)
                    state.suggest.push(state.arraySuggests[0])

                    if (state.arraySuggests[0]) {
                        state.currentText = state.arraySuggests[0].text
                        state.currentImage = state.arraySuggests[0].img
                        state.currentId = state.arraySuggests[0].userId
                        state.currentSuggestId = state.arraySuggests[0].id

                    }
                    console.log(state.currentText)

                    state.status = 'resolved'
                }
            )
            .addCase(
                fetchSuggestData.rejected, (state, action) => {
                    throw new Error('Error from fetchSuggestData')
                }
            )
    }
})

export default adminSlice.reducer
export const { actions: adminActions } = adminSlice