import { createSlice } from "@reduxjs/toolkit";
import { fetchOtherProfileData } from "../services/fetchOtherProfileData";

const initialState = {
    status: null,
    error: null,
    id: undefined,
    acceptedMoments: [],
    wantedMoments: [],
    rejectedMoments: [],
    createdMoments: [],
    pinnedMoments: [],
    avatar: '',
    background: '',
    descr: '',
    name: '',
    nickname: '',

}

const otherProfileDataSlice = createSlice({
    name: 'otherProfileData',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(
                fetchOtherProfileData.pending, (state) => {
                    state.status = 'loading'
                    // state.error = null
                }
            )
            .addCase(
                fetchOtherProfileData.fulfilled, (state, action) => {
                    // state.collections = action.payload
                    // state.profileData = action.payload[0]
                    state.id = action.payload[0].id
                    state.acceptedMoments = action.payload[0].acceptedMoments
                    state.wantedMoments = action.payload[0].wantedMoments
                    state.rejectedMoments = action.payload[0].rejectedMoments
                    state.createdMoments = action.payload[0].createdMoments
                    state.pinnedMoments = action.payload[0].pinnedMoments
                    state.avatar = action.payload[0].avatar
                    state.background = action.payload[0].background
                    state.descr = action.payload[0].descr
                    state.name = action.payload[0].name
                    state.nickname = action.payload[0].nickname

                    state.status = 'resolved'

                }
            )
            .addCase(
                fetchOtherProfileData.rejected, (state, action) => {
                    // throw new Error('Error from fetchOtherProfileData')
                    state.error = 'error'
                }
            )
    }
})

export default otherProfileDataSlice.reducer
export const { actions: otherProfileDataSliceActions } = otherProfileDataSlice