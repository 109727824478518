import { FloatModal } from "shared/ui/FloatModal"
import { Text, TextAlign, TextSize, TextWeight } from "shared/ui/Text/Text"
import cls from "./SubscribeFloat.module.scss"
import { Button } from "shared/ui/Button"
import logo from 'shared/assets/icons/AppIcon2.png'
import install from 'shared/assets/icons/IconSubscribe.png'

export const SubscribeFloat = (props) => {
    const { closeSubscribeFloat, onSubscribe } = props

    return (
        <FloatModal onClose={closeSubscribeFloat}>
            <div className={cls.ImageContainer}>
                <img className={cls.Image} alt='TagLife' src={logo}></img>
                <img className={cls.Icon} alt='TagLife' src={install}></img>
            </div>
            <Text
                className={cls.TitleTextFloat}
                size={TextSize.XL}
                align={TextAlign.LEFT}
                weight={TextWeight.BOLD}
            >
                Понравились достижения?
            </Text>
            <Text
                className={cls.TextFloat}
                align={TextAlign.LEFT}
                weight={TextWeight.MED}
            >
                Подпишись на наш канал, чтобы узнавать об обновлениях и новинках
            </Text>
            <Button
                onClick={onSubscribe}
                className={cls.FloatButton}
                text={'Подписаться на наш канал'}>
            </Button>
        </FloatModal>
    )
}