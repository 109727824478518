import { useDispatch, useSelector } from "react-redux"
import { CardButton } from "shared/ui/CardButton"
import cls from './Choosebar.module.scss'
import { authActions, getArrayCard, getIsLoaded } from "features/anonymousAuth"
import { specialActions } from "features/specialData/model/slice/specialSlice"
import { memo } from "react"
import { classNames } from "shared/lib/classNames/classNames"
import { getSpecialArrayCard } from "features/specialData"
import { adminActions } from "features/anonymousAuth/model/slice/adminSlice"

export const AdminChoosebar = memo((props) => {
    const { activeButton, onOpenFloatCollection, onOpenFloatSpeak } = props
    const dispatch = useDispatch()
    // const isLoaded = useSelector(getIsLoaded)
    const currentImage = useSelector((state) => state.admin.currentImage)
    const currentText = useSelector((state) => state.admin.currentText)
    const currentSpeak = useSelector((state) => state.admin.currentSpeak)

    // let suggestData = useSelector((state) => state.admin.arraySuggests)
    // suggestData = suggestData.slice().reverse()
    // const showedSuggestData = [...suggestData.splice(0, 1)]

    const onAccept = () => {
        if (currentImage && currentText.length > 0) {
            dispatch(adminActions.acceptMoment([1000, 0]))
        }
    }

    const onReject = () => {
        dispatch(adminActions.rejectMoment([-1000, 0]))
    }

    return (
        <div className={classNames(cls.Choosebar, {}, [])}>
            <CardButton
                activeButton={activeButton}
                action={onReject}
                type='reject'
            />
            <CardButton
                activeButton={activeButton}
                currentSpeak={currentSpeak}
                action={onOpenFloatSpeak}
                type='speak'
            />
            <CardButton
                activeButton={activeButton}
                action={onOpenFloatCollection}
                type='collections'
            />
            <CardButton
                activeButton={activeButton}
                action={onAccept}
                type='accept'
            />
        </div>
    )
})