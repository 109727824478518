import { Text, TextSize, TextWeight } from 'shared/ui/Text/Text'
import cls from './NotFoundPage.module.scss'
import whale from 'shared/assets/images/whale1.png'
import { Button } from 'shared/ui/Button'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { navActions } from 'entities/NavMenu'

export const NotFoundPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onRedirect = () => {
        navigate('/')
    }

    useEffect(() => {
        dispatch(navActions.setVisible(false))
    }, [])

    return (
        <div className={cls.NotFound}>
            <img className={cls.Image} alt='TagLife' src={whale}></img>
            <Text className={cls.Text} weight={TextWeight.BOLD} size={TextSize.XL}>404... приплыли...</Text>
            <Text className={cls.Text}>Кит все обыскал, но не смог найти твою страничку( Попробуй начать искать с главной</Text>
            <Button onClick={onRedirect} className={cls.Button} text={'Хорошо, отдыхай кит!'}></Button>
        </div>
    )
}