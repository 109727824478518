import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pushPossibility: false,
    installPossibility: false,
    pushesGranted: false,
    mode: 'app',
    agent: null,
}

const modeLogicSlice = createSlice({
    name: 'modeLogicSlice',
    initialState,
    reducers: {
        setPushPossibility(state, action) {
            state.pushPossibility = action.payload
        },
        setInstallPossibility(state, action) {
            state.installPossibility = action.payload
        },
        setPushesGranted(state, action) {
            state.pushesGranted = action.payload
        },
        setMode(state, action) {
            state.mode = action.payload
        },
        setAgent(state, action) {
            state.agent = action.payload
        }
    },
})

export default modeLogicSlice.reducer
export const { actions: modeActions } = modeLogicSlice