import { classNames } from "shared/lib/classNames/classNames"
import cls from "./Banner.module.scss"
import whale from "shared/assets/images/whale4.png"

export const Banner = (props) => {
    const { children } = props

    return (
        <div className={classNames(cls.Banner, {}, [])}>
            <img className={cls.Whale} src={whale} alt="whale"></img>
            {children}
        </div>
    )
}