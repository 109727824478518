import { FloatModal } from "shared/ui/FloatModal"
import { Text, TextAlign, TextSize, TextWeight } from "shared/ui/Text/Text"
import cls from "./AnnounceFloat.module.scss"
import whale from 'shared/assets/images/whale4.png'
import { Button } from "shared/ui/Button"

export const AnnounceFloat = (props) => {
    const { closeAnnounceFloat, navigateToSuggest } = props

    return (
        <FloatModal onClose={closeAnnounceFloat}>
            <img className={cls.FloatWhale} src={whale} alt="whale"></img>
            <Text
                className={cls.TitleTextFloat}
                size={TextSize.XL}
                align={TextAlign.LEFT}
                weight={TextWeight.BOLD}
            >
                Коллекция скоро появится
            </Text>
            <Text
                className={cls.TextFloat}
                align={TextAlign.LEFT}
                weight={TextWeight.MED}
            >
                Создавай мечты которые хочешь увидеть в ней
            </Text>
            <Button
                onClick={navigateToSuggest}
                className={cls.FloatButton}
                text={'Создать свою мечту'}>
            </Button>
        </FloatModal>
    )
}